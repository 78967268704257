/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { MdCheck } from 'react-icons/md';
import App from '../../../Images/Messenger/messenger-app.png';
import { eiyaColor as colors } from '../../../utils/colors';

const innerHeight = window.innerHeight - 60;
const realHeight = `${innerHeight}px`;

const SectionWraper = styled.div`
  height: ${realHeight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 9rem;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 3rem 1rem;
    height: 100%;
    width: 100%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.p`
  font-size: 32px;
  color: ${colors.eiyaPurple};
  font-weight: 700;
  margin-bottom: 6rem;

  @media screen and (max-width: 900px) {
    margin-bottom: 3rem;
  }
`;

const SectionContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  align-items: baseline;

  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

const CheckIcon = styled(MdCheck)`
  color: ${colors.eiyaPurple};
  margin-right: 1rem;
  font-size: 24px;
`;

const SectionDescription = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.textDark};
  margin-bottom: 1rem;
`;

var isSafari = /constructor/i.test(window.HTMLElement);

const SectionImg = styled.img`
  width: auto;
  height: inherit;
  padding-top: 6rem;

  @media screen and (max-width: 900px) {
    padding-top: 0;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    height: auto;

    ${isSafari} {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    ${isSafari} {
      width: 100%;
    }
  }
`;

function ThirdSection() {
  return (
    <SectionWraper>
      <SectionContainer>
        <SectionTitle>Tu aplicación será tu aliada de trabajo</SectionTitle>
        <SectionContentContainer>
          <CheckIcon />
          <SectionDescription>Conoce cuánto ganarás por cada entrega</SectionDescription>
        </SectionContentContainer>
        <SectionContentContainer>
          <CheckIcon />
          <SectionDescription>Consulta el historial de tu trabajo</SectionDescription>
        </SectionContentContainer>
        <SectionContentContainer>
          <CheckIcon />
          <SectionDescription>Visualiza tu ruta y tus siguientes tareas</SectionDescription>
        </SectionContentContainer>
        <SectionContentContainer>
          <CheckIcon />
          <SectionDescription>Soporte inmediato para cualquier contratiempo</SectionDescription>
        </SectionContentContainer>
      </SectionContainer>
      <SectionImg src={App} alt="messenger app" />
    </SectionWraper>
  );
}

export default ThirdSection;
