import React from 'react';
import styled from 'styled-components';
import AboutImg from '../../../Images/About/conocenos-header.png';
import { eiyaColor as colors } from '../../../utils/colors';

const { innerHeight } = window;
const realHeight = `${innerHeight}px`;

const BackgroundImage = styled.div`
  background: ${colors.white};
  background-image: url(${AboutImg});
  background-repeat: no-repeat;
  background-size: cover;
  height: ${realHeight};
  width: 100%;
  z-index: 1;

  @media screen and (max-width: 900px) {
    
  }
`;

const BackgroundGradient = styled.div`
  background: linear-gradient(180deg, #64499e 7.17%, rgba(100, 73, 158, 0) 63.78%);
  height: ${realHeight};
  width: 100%;
  
  @media screen and (max-width: 900px) {
padding-top: 120px;  }
`;

const BackgroundWraper = styled.div`
  width: 100%;
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  color: ${colors.white};
  padding: 0 9rem;

  @media screen and (max-width: 900px) {
    height: auto;
    padding: 0 1rem;
  }
`;

const BackgroundTitle = styled.p`
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 4rem;


  @media screen and (max-width: 900px) {
    
  }
`;

const BackgroundContent = styled.p`
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 4rem;
  width: 40%;


  @media screen and (max-width: 900px) {
    width: 100%;
    
  }
`;

function HeroSection() {
  return (
    <BackgroundImage>
      <BackgroundGradient>
        <BackgroundWraper
        >
          <BackgroundTitle
          >
            ¡Bienvenido a Eiya!
          </BackgroundTitle>
          <BackgroundContent
          >
            Somos el puente entre los negocios y sus clientes
          </BackgroundContent>
        </BackgroundWraper>
      </BackgroundGradient>
    </BackgroundImage>
  );
}

export default HeroSection;
