import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import Logo from '../../Images/eiya-logo-white.svg';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  /* FooterLink, */
  FooterLinkA,
  SocialMediaWrap,
  SocialMedia,
  SocialLogo,
  WebsiteRights,
  SocialIconLink,
  SocialIcons,
  FooterLogo,
} from './FooterElements';

function Footer() {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Servicios</FooterLinkTitle>
              <FooterLinkA href="https://ayuda.eiya.mx/hc/es-mx/articles/360002544294-C%C3%B3mo-Funciona-Env%C3%ADos" target='_blank'>Envios</FooterLinkA>
              {/* <FooterLink to="/fulfillment">Fulfillment</FooterLink>
              <FooterLinkA href="https://www.tienda.eiya.mx/" target="_blank">Insumos</FooterLinkA> */}
              <FooterLinkA href="https://ayuda.eiya.mx/hc/es-mx/articles/360037665094-Tarifas-y-Cobertura-mapa-" target="_blank">Tarifas</FooterLinkA>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Producto</FooterLinkTitle>
              <FooterLinkA href="https://developers.eiya.mx/" target="_blank">
                Eiya API
              </FooterLinkA>
              <FooterLinkA
                href="https://play.google.com/store/apps/details?id=com.eiyamessengerapp&hl=es_MX"
                target="_blank"
              >
                Quiero ser mensajero
              </FooterLinkA>
              <FooterLinkA href="https://envios.eiya.mx/login" target="_blank">
                Portal de envios
              </FooterLinkA>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Legal</FooterLinkTitle>
              <FooterLinkA
                href="https://ayuda.eiya.mx/hc/es-mx/articles/360003176534-T%C3%A9rminos-y-Condiciones-Env%C3%ADos-Portal"
                target="_blank"
              >
                Terminos y condiciones
              </FooterLinkA>
              <FooterLinkA
                href="https://ayuda.eiya.mx/hc/es-mx/articles/360005040133-Aviso-de-Privacidad"
                target="_blank"
              >
                Politica de privacidad
              </FooterLinkA>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contacto</FooterLinkTitle>
              <FooterLinkA href="https://ayuda.eiya.mx/hc/es-mx" target="_blank">
                Centro de ayuda
              </FooterLinkA>
              <FooterLinkA href="mailto:ayuda@eiya.mx">ayuda@eiya.mx</FooterLinkA>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
              <FooterLogo src={Logo} />
            </SocialLogo>
            <WebsiteRights>Envios para tiendas en linea</WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/eiyamx/"
                target="_blank"
                arial-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://twitter.com/eiyamx/"
                target="_blank"
                arial-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.instagram.com/eiyamx/"
                target="_blank"
                arial-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="https://api.whatsapp.com/send?phone=525659157862"
                target="_blank"
                arial-label="Whatsapp"
              >
                <FaWhatsapp />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/channel/UCdxznk4UEmDQTn2tN6Dy4JQ"
                target="_blank"
                arial-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;
