import React from 'react';
import styled from 'styled-components';
import { IoMdInformationCircle } from 'react-icons/io';
import HIW1 from '../../../Images/Messenger/messenger-step-1.png';
import HIW2 from '../../../Images/Messenger/messenger-step-2.png';
import HIW3 from '../../../Images/Messenger/messenger-step-3.png';
import arrows from '../../../Images/arrows.png';
import { eiyaColor as colors } from '../../../utils/colors';

const innerHeight = window.innerHeight + 60;
const realHeight = `${innerHeight}px`;

const SectionWraper = styled.div`
  background: url(${arrows});
  background-size: contain;
  background-position: top;
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 9rem;

  @media screen and (max-width: 1300px) {
    margin-top: 4rem;
  }

  @media screen and (max-width: 900px) {
    background: ${colors.purplite};
    padding: 3rem 1rem;
    height: 100%;
    width: 100%;
  }
`;

const SectionTitle = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: ${colors.eiyaPurple};
  margin-bottom: 6rem;

  @media screen and (max-width: 1300px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 3rem;
    text-align: center;
  }
`;

const SectionContentWraper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 6rem;

  @media screen and (max-width: 1300px) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const SectionContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  width: 30%;

  @media screen and (max-width: 900px) {
    width: 100%;
`;

const SectionContentImg = styled.img`
  width: 66%;
  zindex: 2;
  margin-bottom: 2rem;
`;

const SectionYellowToolBox = styled.div`
  background: ${colors.babyYellow};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  width: 80%;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    width: 100%;
  }
`;

function SecondSection() {
  return (
    <SectionWraper>
      <SectionTitle>Descarga la aplicación y regístrate</SectionTitle>
      <SectionContentWraper>
        <SectionContentCard>
          <SectionContentImg src={HIW1} alt="cuenta" />
          <p
            style={{
              fontSize: '24px',
              fontWeight: '700',
              color: colors.textDark,
              marginBottom: '1rem',
            }}
          >
            Crea tu cuenta
          </p>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '400',
              color: colors.textBody,
              textAlign: 'center',
            }}
          >
            Descarga la aplicación, ingresa tus datos personales y toma una selfie para tu registro.
          </p>
        </SectionContentCard>
        <SectionContentCard>
          <SectionContentImg src={HIW2} alt="cuenta" />
          <p
            style={{
              fontSize: '24px',
              fontWeight: '700',
              color: colors.textDark,
              marginBottom: '1rem',
            }}
          >
            Carga tus documentos
          </p>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '400',
              color: colors.textBody,
              textAlign: 'center',
            }}
          >
            Selecciona tu tipo de vehículo, toma foto de tus documentos, pasarán a revisión y si
            eres seleccionado nos pondremos en contacto contigo.
          </p>
        </SectionContentCard>
        <SectionContentCard>
          <SectionContentImg src={HIW3} alt="cuenta" />
          <p
            style={{
              fontSize: '24px',
              fontWeight: '700',
              color: colors.textDark,
              marginBottom: '1rem',
            }}
          >
            Agenda tu entrenamiento
          </p>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '400',
              color: colors.textBody,
              textAlign: 'center',
            }}
          >
            Una vez que nuestro equipo revise tu documentación se comunicarán contigo para agendar
            tu entrenamiento y finalizar tu registro.
          </p>
        </SectionContentCard>
      </SectionContentWraper>
      <SectionYellowToolBox>
        <IoMdInformationCircle
          style={{ color: colors.accentYellow, fontSize: '30px', marginRight: '1rem' }}
        />
        <p style={{ fontSize: '18px', fontWeight: '500', color: colors.textDark }}>
          El tiempo de espera para la revisión de documentos puede demorar debido a que recibimos
          muchas solicitudes y nos tomamos muy en serio el perfil de nuestros mensajeros; sin
          embargo, recuerda que recibirás correos y notificaciones en tu app en el transcurso.
        </p>
      </SectionYellowToolBox>
    </SectionWraper>
  );
}

export default SecondSection;
