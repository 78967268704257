import React, { useState } from 'react';
import styled from 'styled-components';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import { eiyaColor as colors } from '../../utils/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3rem 2rem;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  
`;

const TextContainer = styled.div`
  width: 50%;

  @media screen and (max-width: 900px) {
    width: auto;
  }
`;

const Title = styled.h2`
  color: ${colors.textDark};
  margin: 40px 0 28px;

  @media screen and (max-width: 900px) {
    margin: 0 0 28px;
  }
`;

const StyleP = styled.p`
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.textBody};
  text-align: justify;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${colors.eiyaPurple};
`;

const List = styled.ol`
  margin: 24px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.textBody};
  text-align: justify;
`;

function MessengerData() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar isOpen={isOpen} toggle={toggle} />
      <Container>
        <TextContainer style={{ margin: '0 24px' }}>
          <Title style={{}}>Manejo de datos personales Mensajeros</Title>
          <StyleP>
            En Eiya estamos comprometidos con tu información. Para conocer las politicas de manejo de 
            datos para mensajeros puedes visitar el aviso de privacidad para la aplicación móvil de 
            <Link
              href="https://ayuda.eiya.mx/hc/es-mx/articles/360045727314-Aviso-de-Privacidad-App-M%C3%B3vil-Mensajeros"
              target="_blank"
              rel="noreferrer"
            >
              {' "Eiya mensajeros"'}
            </Link>
          </StyleP>
          <StyleP>
            Si deseas que tu información sea retirada de nuestro sistema puedes realizar una petición 
            realizando una solicitud al correo ayuda@eiya.mx o bien puedes utilizar el siguiente 
            formulario.
          </StyleP>
          <StyleP>
            Al realizar la solicitud en el siguiente formulario/correo el equipo de soporte se comunicará 
            contigo para empezar el procedimiento de revocación o limitación de consentimiento ARCO en la 
            aplicación de "Eiya Mensajeros", el cual puedes revisar a detalle en nuestro 
            <Link
              href="https://ayuda.eiya.mx/hc/es-mx/articles/360037244054-Manual-de-Procedimiento-de-Derechos-ARCO"
              target="_blank"
              rel="noreferrer"
            >
              {' Manual de Procedimiento de Derechos ARCO'}
            </Link>.
          </StyleP>
          <h4 style={{ color: colors.textDark, margin: '24px 0 0 0' }}>
            Los pasos generales son los siguientes
          </h4>
          <List>
            <li>Generar una solicitud con tu petición de manejo de datos personales en el formulario o al correo ayuda@eiya.mx</li>
            <li>Compartir formato y requisitos para autorizar el cambio en manejo de datos personales</li>
            <li>El área de datos personales revisará la petición de tu solicitud</li>
            <li>Notificación de resolución del caso con constancia</li>
          </List>
          <StyleP>
            Los cambios en datos personales se ejecutarán en los siguientes 30 días naturales dando 
            constancia de la acción. Toma en cuenta que datos historicos de operaciones realizadas en 
            la plataforma pueden almacenarce para fines operativos, administrativos o legales.
          </StyleP>
        </TextContainer>
        <TextContainer>
          <iframe
            title="form personal data"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdnAqIYi4OZ0RNHtA9Bipzp4l7LeQJWCAhQmrsdQto9njOQ-A/viewform?embedded=true" 
            width="100%" 
            height="708" 
            frameborder="0" 
            marginheight="0" 
            marginwidth="0"
          >Cargando…</iframe>
        </TextContainer>
      </Container>
      <Footer />
    </>
  );
}

export default MessengerData;