import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
/* import Home from './Pages'; */
import SignIn from './Pages/signin';
/* import ShippingSection from './Pages/Shipping'; */
import HomeSection from './Pages/Home';
import MessengerSection from './Pages/Messenger';
import AboutInfoSection from './Pages/About';
/* import FulfillmentSection from './Pages/Fulfillment'; */
import MessengerData from './Pages/MessengerData';

function App() {

  return (
    <Router >
      <Routes>
        {/* <Route path="/" element={<Home />} exact /> */}
        <Route path="/" element={<HomeSection />} exact />
        <Route path="/signin" element={<SignIn />} exact />
        {/* <Route path="/envios" element={<ShippingSection />} exact /> */}
        {/* <Route path="/fulfillment" element={<FulfillmentSection />} exact /> */}
        <Route path="/mensajeros" element={<MessengerSection />} exact />
        <Route path="/conocenos" element={<AboutInfoSection />} exact />
        <Route path="/messengers_data" element={<MessengerData />} exact />
      </Routes>
    </Router>
  );
}

export default App;
