/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';
import ReviewData from './ReviewData';
import { eiyaColor as colors } from '../../../utils/colors';

const CarouselContainer = styled.div`
  margin-right: 9rem;

  @media screen and (max-width: 1430px) {
    margin-right: 6rem;
  }

  @media screen and (max-width: 1330px) {
    margin-right: 3rem;
  }

  @media screen and (max-width: 1230px) {
    margin-right: 3rem;
  }

  @media screen and (max-width: 1170px) {
    margin-right: 1rem;
  }

  @media screen and (max-width: 900px) {
    margin: 0rem 1rem 1rem 1rem;
  }
`;

export default function ShippingReviews() {
  return (
    <CarouselContainer>
      <Carousel
        animation="slide"
        indicatorIconButtonProps={{
          style: {
            color: colors.accentYellow,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: colors.brownYellow,
          },
        }}
        sx={{
          width: 'max-content',
        }}
      >
        {ReviewData.map((item) => (
          <ReviewCard item={item} />
        ))}
      </Carousel>
    </CarouselContainer>
  );
}

const BrandReviewWraper = styled.div`
  max-width: 1000px;
`;

const BrandReviewContainer = styled.div`
  background: url(${(props) => props.image});
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;

  @media screen and (max-width: 900px) {
    padding: 3rem;
  }

  @media screen and (max-width: 400px) {
    padding: 2rem;
  }
`;

const BrandReviewCard = styled.div`
  background: ${colors.white};
  border: 5px solid #0b1230;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 4rem;
  text-align: center;

  @media screen and (max-width: 1430px) {
    padding: 3rem;
  }

  @media screen and (max-width: 1330px) {
    padding: 3rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 3rem;
  }

  @media screen and (max-width: 1170px) {
    padding: 1rem;
  }
  
  @media screen and (max-width: 900px) {
    padding: 1rem;
  }
`;

const BrandReview = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;


  @media screen and (max-width: 1400px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
  }

  @media screen and (max-width: 900px) {
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
  }
`;

const BrandLogo = styled.img`
  width: ${(props) => (props ? props.size : '100%')};
  padding: 0 2rem;
  margin-top: 3rem;
  
  @media screen and (max-width: 1800px) {
    width: 60%;
  }

  @media screen and (max-width: 1300px) {
    width: 80%;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
    width: 40%;
  }

  @media screen and (max-width: 800px) {
    margin-top: 2rem;
    width: 45%;
  }

  @media screen and (max-width: 700px) {
    margin-top: 2rem;
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    margin-top: 2rem;
    width: 70%;
  }

  @media screen and (max-width: 500px) {
    margin-top: 2rem;
    width: 90%;
  }

  @media screen and (max-width: 400px) {
    margin-top: 2rem;
    width: 100%;
  }
`;

function ReviewCard(props) {
  return (
    <BrandReviewWraper>
      <BrandReviewContainer image={props.item.image}>
        <BrandReviewCard>
          <BrandReview>{props.item.review}</BrandReview>
          <BrandLogo src={props.item.logo.img} alt="logo" size={props.item.logo.size} />
        </BrandReviewCard>
      </BrandReviewContainer>
    </BrandReviewWraper>
  );
}
