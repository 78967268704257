import styled from 'styled-components';
import { NavLink as Route } from 'react-router-dom';
import { eiyaColor as colors } from '../../utils/colors';
import { ButtonAPurple } from '../ButtonElement';

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? colors.white : colors.white)};
  height: 80px;
  /*margin-top: -80px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 900px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 9rem;
  max-width: inherit;

  @media screen and (max-width: 1430px) {
    padding: 0 6rem;
    height: auto;
  }

  @media screen and (max-width: 1330px) {
    padding: 0 3rem;
    height: auto;
  }

  @media screen and (max-width: 1230px) {
    padding: 0 3rem;
    height: auto;
  }

  @media screen and (max-width: 1170px) {
    padding: 1rem;
    height: auto;
  }

  @media screen and (max-width: 900px) {
    padding: 1rem;
  }
`;

export const NavLogo = styled(Route)`
  color: ${colors.white};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 3rem;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileNavLogo = styled(Route)`
  color: ${colors.white};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
  display: none;

  @media screen and (max-width: 900px) {
    display: contents;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: ${colors.eiyaPurple};
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 900px) {
    display: contents;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: ${colors.accentYellow};
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(Route)`
  color: ${colors.textDark};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid ${colors.eiyaPurple};
    color: ${colors.eiyaPurple};
  }

  &.isActive {
    color: ${colors.eiyaPurple};
  }

  &.selected {
    color: ${colors.eiyaPurple};
  }
`;

export const NavLink = styled.select`
  background: ${colors.white};
  color: ${colors.textDark};
  font-size: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  border: none;

  &.focus-visible {
    outline: none;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    border-bottom: 3px solid ${colors.eiyaPurple};
    color: ${colors.eiyaPurple};
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavBtnLink = styled(ButtonAPurple)`
`;

export const NavBtnLinks = styled.a`
  color: ${colors.textDark};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  text-decoration: none;

  &:hover {
    color: ${colors.eiyaPurple};
  }

  @media screen and (max-width: 940px) {
    text-align: end;
    padding: 0 1rem;
    padding-right: 0;
  }

  @media screen and (max-width: 900px) {
    padding: 7px 10px;
  }
`;

export const NavLogoImg = styled.img`
  margin-right: 3rem;
  @media screen and (max-width: 900px) {
    width: 10%;
  }
  @media screen and (max-width: 800px) {
    width: 10%;
  }
  @media screen and (max-width: 700px) {
    width: 15%;
  }
  @media screen and (max-width: 500px) {
    width: 20%;
  }
`;
