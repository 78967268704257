/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { MdCheck } from 'react-icons/md';
import Dashboard from '../../../Images/Shipping/envios-plataforma.png';
import { eiyaColor as colors } from '../../../utils/colors';

const { innerHeight } = window;
const realHeight = `${innerHeight}px`;

var isSafari = /constructor/i.test(window.HTMLElement);

const SectionWraper = styled.div`
  background: ${colors.eiyaPurple};
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  margin-bottom: 15rem;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 3rem 1rem;
    height: 100%;
    margin-bottom: 3rem;
  }
`;

const SectionTitle = styled.p`
  font-size: 32px;
  color: ${colors.white};
  font-weight: 700;
  margin-bottom: 4rem;
  line-height: 38.4px;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
  }
`;

const SectionContentWraper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.white};
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 9rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 1430px) {
    padding: 0 6rem;
  }

  @media screen and (max-width: 1330px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 1170px) {
    padding: 0 1rem;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: baseline;
    padding: 0 1rem;
  }
`;

const SectionContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

const SectionContent = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: 900px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }

`;

const SectionImg = styled.img`
  margin: 0 7rem;
  width: -webkit-fill-available;
  width: -moz-available;
  height: -webkit-fill-available;
  height: -moz-available;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
    margin: 0 3rem;
  }

  @media screen and (max-width: 1170px) {
    margin: 0 1rem;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    ${isSafari} {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

function FourthSection() {
  return (
    <SectionWraper>
      <SectionTitle>Una plataforma para crear, analizar y monitorear tus pedidos</SectionTitle>
      <SectionContentWraper>
        <div>
          <SectionContentContainer>
            <MdCheck style={{ fontSize: '24px', marginRight: '2rem' }} />
            <SectionContent>Revisa el estado de cada envío</SectionContent>
          </SectionContentContainer>
          <SectionContentContainer>
            <MdCheck style={{ fontSize: '24px', marginRight: '2rem' }} />
            <SectionContent>Descarga reportes de envíos</SectionContent>
          </SectionContentContainer>
        </div>
        <div>
          <SectionContentContainer>
            <MdCheck style={{ fontSize: '24px', marginRight: '2rem' }} />
            <SectionContent>Consulta comprobantes de cada entrega</SectionContent>
          </SectionContentContainer>
          <SectionContentContainer>
            <MdCheck style={{ fontSize: '24px', marginRight: '2rem' }} />
            <SectionContent>Automatiza tu facturación y mucho más.</SectionContent>
          </SectionContentContainer>
        </div>
      </SectionContentWraper>
      <SectionImg src={Dashboard} alt="dashboard" />
    </SectionWraper>
  );
}

export default FourthSection;
