import React from 'react';
import styled from 'styled-components';
import EcoIcon from '../../../Images/Home/mission/eco-icon.svg';
import MoneyIcon from '../../../Images/Home/mission/money-icon.svg';
import IntegrationIcon from '../../../Images/Home/mission/integration-icon.svg';
import BicycleIcon from '../../../Images/Home/mission/fast-icon.svg';
import SupportIcon from '../../../Images/Home/mission/support-icon.svg';
import LocationIcon from '../../../Images/Home/mission/location-icon.svg';
import { eiyaColor as colors } from '../../../utils/colors';

const SectionWraper = styled.div`
  background: ${colors.ligthGrey};
  height:  ${window.innerHeight}px;
  display: flex;
  flex-direction: column;
  align-items: flex-star;
  justify-content: center;
  padding: 4rem 10rem;

  @media screen and (max-width: 1430px) {
    padding: 2rem 10rem;
  }

  @media screen and (min-width: 1500px) and (max-width: 1699px){
    height:  ${window.innerHeight-100}px;
  }

  @media screen and (min-width: 1700px){
    height:  ${window.innerHeight-150}px;
  }

  @media screen and (max-width: 1330px) {
    padding: 3rem 8rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 2rem 6rem;
  }

  @media screen and (max-width: 1170px) {
    padding: 2rem 3rem;
  }

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: 100%;
    margin-top: 0;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  
`;

const PurpleTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  color: ${colors.eiyaPurple};
`;

const BlackTitle = styled.p`
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: ${colors.generalDark};
  margin: 20px 0;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  @media screen and (max-width: 900px) {
  } 
`;

const Card = styled.a`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding: 12px;
  width: 27%;
  margin: 5px 0;
  text-decoration: none;
  color: ${colors.textDark};

  @media screen and (max-width: 900px) {
    width: 48%;
  } 
`;

const CardTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  margin: 5px 0;
`;

const DescriptionText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
`;

const IconImg = styled.img`
  width: 25px;
  height; 25px;
`;

function MissionSection() {
  return (
    <SectionWraper>
      <TitleSection>
        <PurpleTitle>¿Por qué Eiya?</PurpleTitle>
        <BlackTitle>
          Nuestra misión es ser tu 
          <b>{' aliado '}</b>
          para que tus clientes tengan la 
          <b>{' mejor experiencia de entrega'}</b>
        </BlackTitle>
      </TitleSection>
      <CardContainer>
        <Card href="#deliverylink">
          <IconImg src={BicycleIcon} alt="Entrega eficiente" />
          <CardTitle>Entrega rápida y eficiente</CardTitle>
          <DescriptionText>Tecnología y humanos en conjunto</DescriptionText>
        </Card>
        <Card href="#pricelink">
          <IconImg src={MoneyIcon} alt="Precio competitivo" />
          <CardTitle>Precios competitivos</CardTitle>
          <DescriptionText>Siempre las tarifas más accesibles</DescriptionText>
        </Card>
        <Card href="#integrationlink">
          <IconImg src={IntegrationIcon} alt="Integraciónes" />
          <CardTitle>Integraciones</CardTitle>
          <DescriptionText>Para múltiples canales de venta</DescriptionText>
        </Card>
        <Card href="#trackinglink">
          <IconImg src={LocationIcon} alt="Rastreo" />
          <CardTitle>Rastreo de tus paquetes</CardTitle>
          <DescriptionText>Fácil, seguro y disponible en todo momento</DescriptionText>
        </Card>
        <Card target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=525659157862">
          <IconImg src={SupportIcon} alt="Soporte" />
          <CardTitle>Soporte</CardTitle>
          <DescriptionText>Para la satisfacción de tu cliente</DescriptionText>
        </Card>
        <Card href="#ecologiclink">
          <IconImg src={EcoIcon} alt="sustentabilidad" />
          <CardTitle>Sustentabilidad</CardTitle>
          <DescriptionText>~50% de entregas libre de contaminación</DescriptionText>
        </Card>
      </CardContainer>
      <div id="integrationlink" />
    </SectionWraper>
  );
}

export default MissionSection;
