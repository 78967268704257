/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MdAttachMoney, MdPlace, MdSearch } from 'react-icons/md';
import { BiChevronsDown } from "react-icons/bi";
import TextField from '@mui/material/TextField';
/* import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl'; */
import { styled as MUIStyled, createTheme, ThemeProvider } from '@mui/material/styles';
import loader from '../../../Images/loader-white.gif';
import bike from '../../../Images/bike-black.svg';
import { eiyaColor as colors } from '../../../utils/colors';
import { ButtonBPurple, ButtonLYellow } from '../../ButtonElement';
import { getUrlCampaignData } from '../../../utils/helpFunctions';

const customTheme = createTheme({
  root: {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: '10',
    textTransform: 'none',
    color: colors.textDark,
  },
  typography: {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: '10',
    textTransform: 'none',
    color: colors.textDark,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lato';
          font-size: '10';
          font-weight: 400;
          color: colors.textDark,
          src: local('Lato'), local('Lato-Regular'), url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'}) format('lato');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

const InputComponent = MUIStyled(TextField)({
  marginRight: '1rem',
  '& label.Mui-focused': {
    color: '#8058C7',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#8058C7',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#8058C7',
    },
  },
});

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -${window.innerHeight / 3.5}px;
  z-index: 2;

  @media screen and (min-width: 1700px) {
    margin-top: -${window.innerHeight / 3.5}px;
    margin-bottom: ${props => props.coverage && !props.tracking ? 100 : window.innerHeight / 4}px;
  }

  @media screen and (max-width: 1650px) {
    margin-top: -${window.innerHeight / 4}px;
    margin-bottom: ${props => props.coverage && !props.tracking ? 100 : window.innerHeight / 8}px;
  }

  @media screen and (max-width: 1430px) {
    margin-top: -${window.innerHeight / 4}px;
    margin-bottom: ${props => props.coverage && !props.tracking ? 100 : window.innerHeight / 8}px;
  }

  @media screen and (max-width: 1350px) {
    margin-top: -${window.innerHeight / 4}px;
    margin-bottom: ${props => props.coverage && !props.tracking ? 50 : window.innerHeight / 10}px;
  }

  @media screen and (max-width: 1280px) {
    margin-top: -${window.innerHeight / 4}px;
    margin-bottom: ${props => props.coverage && !props.tracking ? 50 : window.innerHeight / 18}px;
  }

  @media screen and (max-width: 900px) {
    bottom: 50px;
    margin: 0;
  }
`;

const SectionFooterWraper = styled.div`
  width: 100%;
  position: relative;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const SectionFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: auto;
  height: 60px;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    top: 100%;
  }
`;

const SectionFooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.faWhite};
  color: ${colors.textDark};
  width: 250px;
  cursor: pointer;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  border: 1px solid ${colors.eiyaPurple};

  &:hover {
    background: ${colors.generalDark};
	color: ${colors.white};
	border: none;
  }

  &.active {
    background: ${colors.eiyaPurple};
	color: ${colors.white};
	border: none;
  }
`;

const SectionFooterContent2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.faWhite};
  color: ${colors.textDark};
  width: 250px;
  cursor: pointer;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border: 1px solid ${colors.eiyaPurple};

  &:hover {
    background: ${colors.generalDark};
	color: ${colors.white};
	border: none;
  }

  &.active {
    background: ${colors.eiyaPurple};
	color: ${colors.white};
	border: none;
  }
`;

const InputWraperBackground = styled.div`
  align-self: center;
  width: max-content;
  position: relative;
  background: ${colors.white};

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const InputWraper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: ${colors.white};
  padding: 1rem 5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
    padding: 1rem 3rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    padding: 1rem;
  }
`;

const CoverageWraper = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  background: ${colors.faWhite};
  padding: 2rem 8rem;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin-top: 1.5rem;
  align-self: center;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
    padding: 2rem;
  }

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
    padding: 1rem;
	width: 100%;
  }
`;

const Loading = styled.img`
  margin-top: 3rem;
  margin-left: 9rem;
  width: 40%;
  display: ${(props) => (props.isVisible ? 'contents' : 'none')};

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
  }
`;

const Li = styled.li`
  background-image: radial-gradient(${colors.accentYellow} 0 70%, transparent 70% 100%);
  background-size: 8px 8px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  padding-left: 20px;
  margin-bottom: 8px;
`;

const AccountButton = styled(ButtonBPurple)`
  margin-top: 1.5rem;

  &:hover {
    color: ${colors.darkPurple};
    background: ${colors.accentYellow};
  }
`;

const CodeWraper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const YellowButton = styled(ButtonLYellow)`
  @media screen and (max-width: 900px) {
    margin-top: 1rem;
	align-self: center;
  }
`;

const SpaceMobile = styled.div`
  @media screen and (max-width: 900px) {
    margin: 0.5rem 0;
  }
`;

const CoverageTitleWraper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const CoverageTitle = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

const CoverageContent = styled.div`
  @media screen and (max-width: 900px) {
    margin: 0 0 1rem 3.5rem;
  }
`;

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

function TrackingComponent() {
  const [form, setForm] = useState({
    id: '',
    originCP: '',
    destinationCP: '',
    weight: '',
    length: '',
    height: '',
    width: '',
  });
  const [tracking, setTracking] = useState(true);
  const [seeBox, setSeeBox] = useState(false);
  const [coverage, setCoverage] = useState(false);
  const [local, setLocal] = useState(false);
  const [national, setNational] = useState(false);
  const [other, setOther] = useState(false);
  const [visible, setvisible] = useState(true);
  const [shippingInfo, setShippingInfo] = useState({
    rate: '',
    coverage: '',
    user: '',
    carrier: '',
  });
  const [putFocus, setPutFocus] = useState(null);
  const [errorId, setErrorId] = useState('');
  const [errorOriginCP, setErrorOriginCP] = useState('');
  const [errorDestinationCP, setErrorDestinationCP] = useState('');
  const [errorWeight, setErrorWeight] = useState('');
  const [errorLength, setErrorLength] = useState('');
  const [errorHeight, setErrorHeigth] = useState('');
  const [errorWidth, setErrorWidth] = useState('');

  const coverageRef = useRef(null);

  const handleClickScroll = () => {
      if (coverageRef) coverageRef.current.scrollIntoView({ block: "center" });
  };

  const handleChange = (e) => {
    const regExNumber = /^\d+$/;
    const regExDecNumber = /^\d{1,2}(\.\d{1,2})?$/;
    const regexTwoNum = /^\d{1,2}?$/;
    const regexPointNum = /^\d{1,2}(\.)?$/;
    if (e.target.name === 'originCP' || e.target.name === 'destinationCP') {
      if (e.target.value === '' || (e.target.value.length <= 5 && regExNumber.test(e.target.value))) {
        setErrorOriginCP('');
        setErrorDestinationCP('');
        const nextFormState = {
          ...form,
          [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
      }
    } else if (e.target.name === 'weight' || e.target.name === 'length' || e.target.name === 'height' || e.target.name === 'width') {
      if (e.target.value === '' || regExDecNumber.test(e.target.value) || regexPointNum.test(e.target.value) || regexTwoNum.test(e.target.value)) {
        switch(e.target.name) {
          case 'weight':
            setErrorWeight('');
            break;
          case 'length':
            setErrorLength('');
            break;
          case 'height':
            setErrorHeigth('');
            break;
          case 'width':
            setErrorWidth('');
            break;
        default:
            break;
        }
        const nextFormState = {
          ...form,
          [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
      }
    } else {
      setErrorId('');
      const nextFormState = {
        ...form,
        [e.target.name]: e.target.value,
      };
      setForm(nextFormState);
    }
  };

  const handleToggle = (e) => {
    if (e.currentTarget.id === 'tracking') {
      setTracking(true);
    } else {
      setTracking(false);
    }
  };

  const validateInfo = () => {
    let isError = false;
    if (form.destinationCP < 5 ) {
      setErrorDestinationCP('El CP debe ser de 5 dígitos');
      isError = true;
    }
    if (form.destinationCP === '') {
      setErrorDestinationCP('Este campo es requerido');
      isError = true;
    }
    if (form.originCP < 5) {
      setErrorOriginCP('El CP debe ser de 5 dígitos');
      isError = true;
    }
    if (form.originCP === '') {
      setErrorOriginCP('Este campo es requerido');
      isError = true;
    }
    if (form.height === '') {
      setErrorHeigth('Este campo es requerido');
      isError = true;
    }
    if (form.length === '') {
      setErrorLength('Este campo es requerido');
      isError = true;
    }
    if (form.width === '') {
      setErrorWidth('Este campo es requerido');
      isError = true;
    }
    if(form.weight === '') {
      setErrorWeight('Este campo es requerido');
      isError = true;
    }
    if(form.weight > 20) {
      setErrorWeight('El peso no puede ser mayor a 20kg');
      isError = true;
    }
    return isError;
  };

  const submitTracking = () => {
    if (form.id.length && errorId === '') {
      const eiya = 'https://envios.eiya.mx/rastreo/';
      const url = `${eiya + form.id}/`;
      return window.open(url, '_self');
    }
    setErrorId('Este campo es requerido');
    return false;
  };

  const submitCP = () => {
    if (form.originCP.length >= 5 && form.destinationCP.length >= 5) {
      setSeeBox(true);
    } else { 
      if (form.destinationCP < 5 ) {
        setErrorDestinationCP('El CP debe ser de 5 dígitos');
      }         
      if (form.destinationCP === '') {
        setErrorDestinationCP('Este campo es requerido');
      }
      if (form.originCP < 5) {
        setErrorOriginCP('El CP debe ser de 5 dígitos');
      }    
      if (form.originCP === '') {
        setErrorOriginCP('Este campo es requerido');
      }     
    };
    // return false;
  };

  const submitEstimate = () => {
    if (!validateInfo()) {
      setvisible(true);
      fetch(`https://api-dashboard.eiya.mx/landing/rates/${form.originCP}/${form.destinationCP}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          width: form.width,
          length: form.length,
          height: form.height,
          weight: form.weight,
        }),
      })
        .then((response) =>
          response.json().then((data) => {
            switch (data.coverage) {
              case 'local':
              case 'regional':
                if (national || other) {
                  setNational(false);
                  setOther(false);
                }
                setLocal(true);
                break;
              case 'national':
                if (local || other) {
                  setOther(false);
                  setLocal(false);
                }
                setNational(true);
                break;
              default:
                if (national || local) {
                  setLocal(false);
                  setNational(false);
                }
                setOther(true);
                break;
            }
            setCoverage(true);
            setShippingInfo(data);
            setvisible(false);
			setPutFocus(true);
          })
        )
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
	if (window.innerWidth > 900) setSeeBox(true);
  }, []);

  useEffect(() => {
    if (putFocus) {
      handleClickScroll();
      setPutFocus(false);
    }
  }, [putFocus])

  return (
    <MainContainer coverage={coverage} tracking={tracking}>
      <SectionFooterWraper>
        <SectionFooterContainer>
          <SectionFooterContent id="tracking" className={tracking ? 'active' : ''} onClick={handleToggle}>
            <MdPlace />
            <p style={{
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '15px',
              marginLeft: '1rem'
            }}>Rastrea tus pedidos</p>
          </SectionFooterContent>
          <SectionFooterContent2 name="estimate" className={tracking ? '' : 'active'} onClick={handleToggle}>
            <MdAttachMoney />
            <p style={{
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '15px',
              marginLeft: '1rem'
            }}>Cotiza tu envío</p>
          </SectionFooterContent2>
        </SectionFooterContainer>
      </SectionFooterWraper>
      <InputWraperBackground>
        <InputWraper>
          {tracking ? (
            <TextFieldContainer style={{ flexDirection: 'row' }}>
              <p
                style={{
                  color: colors.textDark,
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '16px',
				  marginRight: '1rem'
                }}
              >
                Encuentra 
				<br/>
				tu pedido
              </p>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <ThemeProvider theme={customTheme}>
                  <InputComponent
                    name="id"
                    label="Ingresa el número de guía"
                    error={errorId.length > 0}
                    helperText={errorId}
                    value={form.id}
                    onChange={handleChange}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '30px',
                      },
                    }}
                  />
                </ThemeProvider>
                <div
                  style={{
                    background: colors.accentYellow,
                    borderRadius: '50%',
                    width: '2rem',
                    height: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: colors.textDark,
                    cursor: 'pointer',
                  }}
                  onClick={submitTracking}
                >
                  <MdSearch />
                </div>
              </div>
            </TextFieldContainer>
          ) : (
            <TextFieldContainer>
              <div>
                <SpaceMobile />
                <p
                  style={{
                    color: colors.textDark,
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    marginBottom: '1rem',
                  }}
                >
                  Conoce nuestra cobertura y tarifas
                </p>
                <CodeWraper>
                <div style={{ display: 'flex' }}>
                  <ThemeProvider theme={customTheme}>
                    <InputComponent
                      name="originCP"
                      label="Código postal de origen"
                      value={form.originCP}
                      error={errorOriginCP.length > 0}
                      helperText={errorOriginCP}
                      onChange={handleChange}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '30px',
                        },
                      }}
                    />
                    <InputComponent
                      name="destinationCP"
                      label="Código postal de destino"
                      value={form.destinationCP}
                      error={errorDestinationCP.length > 0}
                      helperText={errorDestinationCP}
                      onChange={handleChange}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '30px',
                        },
                        '& .MuiFormControl-root': {
                          marginLeft: '2rem',
                        },
                      }}
                    />
                  </ThemeProvider>
                </div>
                {!seeBox ? <YellowButton onClick={submitCP}><BiChevronsDown/></YellowButton> : null}
              </CodeWraper>
              </div>
              {seeBox ? (
                <div>
                  <SpaceMobile />
                  <p
                    style={{
                      color: colors.textDark,
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '16px',
                      margin: '0 0 1rem 0',
                    }}
                  >
                    Describe el embalaje de tu paquete
                  </p>
                  <CodeWraper>
                    <ThemeProvider theme={customTheme}>
                      <div style={{ display: 'flex' }}>
                      <InputComponent
                        name="weight"
                        label="Peso (Kg)"
                        value={form.weight}
                        error={errorWeight.length > 0}
                        helperText={errorWeight}
                        onChange={handleChange}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '30px',
                          },
                        }}
                      />
                      <InputComponent
                        name="length"
                        label="Largo (cm)"
                        value={form.length}
                        error={errorLength.length > 0}
                        helperText={errorLength}
                        onChange={handleChange}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '30px',
                          },
                          '& .MuiFormControl-root': {
                            marginLeft: '2rem',
                          },
                        }}
                      />
                      </div>
                      <SpaceMobile />
                      <div style={{ display: 'flex' }}>
                      <InputComponent
                        name="height"
                        label="Alto (cm)"
                        value={form.height}
                        error={errorHeight.length > 0}
                        helperText={errorHeight}
                        onChange={handleChange}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '30px',
                          },
                          '& .MuiFormControl-root': {
                            marginLeft: '2rem',
                          },
                        }}
                      />
                      <InputComponent
                        name="width"
                        label="Ancho (cm)"
                        value={form.width}
                        error={errorWidth.length > 0}
                        helperText={errorWidth}
                        onChange={handleChange}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '30px',
                          },
                          '& .MuiFormControl-root': {
                            marginLeft: '2rem',
                          },
                        }}
                      />
                      </div>
                    </ThemeProvider>
                    <YellowButton onClick={submitEstimate}>Cotizar</YellowButton>
                  </CodeWraper>
                </div>
              ) : null}
            </TextFieldContainer>
          )}
          <div ref={coverageRef} />
          <Loading src={loader} alt="loading" isVisible={visible} />
          {coverage && !tracking ? (
            <CoverageWraper>
              {local || national ? (
                <>
                  <CoverageTitleWraper>
                    <CoverageTitle>
                      <img src={bike} alt="bike" />
                      <div style={{ marginLeft: '1rem' }}>
                        <p style={{
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '16px',
                        }}>{local ? 'Envío Local' : 'Envío Nacional'}</p>
                        <p style={{
                          color: colors.eiyaPurple,
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '16px',
                        }}>{local ? 'CDMX/ZM, Toluca, Guadalajara/ZM' : 'Entre zona eiya y el resto del país'}</p>
						{local ? (
                          <p style={{
						    fontSize: '12px',
						    fontWeight: '500',
						    lineHeight: '16px',
						  }}>
						    Entrega mismo día o día siguiente 
						    <br/> 
						    en la misma ciudad
						  </p>
                        ) : null}
                      </div>
                    </CoverageTitle>
                    <CoverageContent >
                      <p style={{
                        fontSize: '24px',
                        fontWeight: '600',
                        lineHeight: '24px',
                      }}>
						${shippingInfo.rate} MXN
					  </p>
					  <p style={{
						fontSize: '12px',
						fontWeight: '500',
						lineHeight: '16px'
					  }}>
						*Impuesto incluido
					  </p>
                      <p 
					    style={{ 
						  fontSize: '12px',
						  fontWeight: '500',
						  lineHeight: '16px', 
					    }}
					  >
						¿Tienes +50 envíos al mes?
						<br />
						<a 
						  href="https://api.whatsapp.com/send?phone=525659157862"
						  target="_blank"
						  rel="noreferrer"
						  style={{ textDecoration: 'none' }}
						>
						  Contáctanos
						</a>
						{' para mejores precios'}
					  </p>
                    </CoverageContent>
					<CoverageContent>
                      {local ? (
                        <ul style={{
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '16px',
                          listStyleType: 'none'
                        }}>
                          <Li>Incluye 2 intentos de entrega.</Li>
                          <Li>Rastreo y seguimiento en tiempo real.</Li>
						  <Li>Comprobante de entrega electronico.</Li>
                          <Li>Soporte de Eiya para cualquier tema.</Li>
                        </ul>
                      ): (
                        <ul style={{
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '16px',
                          listStyleType: 'none',
                        }}>
                          <Li>Políticas y tarifas aplicadas por paquetería.</Li>
                          <Li>Comprobante de entrega electrónico.</Li>
                          <Li>Soporte de Eiya para cualquier tema.</Li>
                        </ul>
                      )}
                    </CoverageContent>
                  </CoverageTitleWraper>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <AccountButton onClick={() => window.open(getUrlCampaignData(), '_self')}>
                      Crea tu cuenta y envía
                    </AccountButton>
                  </div>
                </>
              ) : null}
              {other ? (
                <>
                  <CoverageTitleWraper>
                    <CoverageTitle>
                      <img src={bike} alt="bike" />
                      <div style={{
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '16px',
                        marginLeft: '1rem'
                      }}>
                        <p style={{
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '16px',
                        }}>Envío sin cobertura</p>
                      </div>
					  
                    </CoverageTitle>
                    <div>
                      <ul style={{
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '16px',
                        listStyleType: 'none'
                      }}>
                        <Li>Incluye 2 intentos de entrega.</Li>
                        <Li>Rastreo y seguimiento en tiempo real.</Li>
                        <Li>Comprobante de entrega electronico.</Li>
                        <Li>Soporte de Eiya para cualquier tema.</Li>
                      </ul>
                    </div>
                  </CoverageTitleWraper>
                  <AccountButton
                    onClick={() => window.open(getUrlCampaignData(), '_self')}
                    style={{ justifySelf: 'center' }}
                  >
                    Crea tu cuenta y envía
                  </AccountButton>
                </>
              ) : null}
            </CoverageWraper>
          ) : null}
        </InputWraper>
      </InputWraperBackground>
    </MainContainer>
  );
}
export default TrackingComponent;
