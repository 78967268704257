import React from 'react';
import styled from 'styled-components';
import Tracking from '../../../Images/Shipping/envios-rastreo.png';
import TrackingM from '../../../Images/tracking-example-mobile.png';
/* import New from '../../../Images/new.png';
 */ import support from '../../../Images/support-icon.svg';
import notification from '../../../Images/notification-icon.svg';
import place from '../../../Images/place-icon.svg';
import { eiyaColor as colors } from '../../../utils/colors';

const { innerHeight } = window;
const realHeight = `${innerHeight}px`;

const SectionWraper = styled.div`
  height: ${realHeight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 9rem;

  @media screen and (max-width: 1430px) {
    padding: 0 6rem;
  }

  @media screen and (max-width: 1330px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 1170px) {
    padding: 0 1rem;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0 1rem;
    padding-top: 3rem;
    height: 100%;
    width: 100%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%;
  }
`;

const SectionTitle = styled.p`
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: ${colors.eiyaPurple};
  margin-bottom: 6rem;

  @media screen and (max-width: 1430px) {
    margin-bottom: 6rem;
  }

  @media screen and (max-width: 1330px) {
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 1230px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 1170px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const SectionDescriptionWraper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: ${colors.eiyaPurple};
`;

const SectionImg = styled.img`
  width: auto;
  height: inherit;
  padding-top: 10rem;

  @media screen and (max-width: 1230px) {
    padding-top: 15rem;
  }
  
  @media screen and (max-width: 1170px) {
    padding-top: 15rem;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const SectionImgM = styled.img`
  display: none;

  @media screen and (max-width: 900px) {
    width: 70%;
    padding-top: 2rem;
    display: flex;
    align-self: flex-end;
  }
`;

const SectionIcon = styled.img`
  margin-top: 2px;
  align-self: flex-start;

  @media screen and (max-width: 900px) {
    margin-top: 5px;
  }
`;

const SectionSubTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.textDark};
  margin-bottom: 1rem;
  
  @media screen and (max-width: 900px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
  }
`;

const SectionDescripotion = styled.p`
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left; 
  color: ${colors.textBody};
  margin-bottom: 2rem;

  @media screen and (max-width: 900px) {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
  }
`;

function ThirdSection() {
  return (
    <SectionWraper>
      <SectionContainer>
        <SectionTitle>
          Ofrece a tus clientes una experiencia única al rastrear sus pedidos
        </SectionTitle>
        <SectionDescriptionWraper>
          <SectionIcon src={notification} alt="notificaciones" />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem' }}>
            <SectionSubTitle>
              Notificación automática por correo y sms
            </SectionSubTitle>
            <SectionDescripotion>
              Al crear una guía en automático enviaremos el rastreo a tu cliente para que siga su
              envío.
            </SectionDescripotion>
          </div>
        </SectionDescriptionWraper>
        <SectionDescriptionWraper>
          <SectionIcon src={support} alt="soporte" />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem' }}>
            <SectionSubTitle>
              Soporte para tu cliente
            </SectionSubTitle>
            <SectionDescripotion>
              Desde el rastreo tu cliente puede solicitar ayuda para reprogramar la fecha de entrega
              y hasta cambiar la dirección.
            </SectionDescripotion>
          </div>
        </SectionDescriptionWraper>
        <SectionDescriptionWraper>
          <SectionIcon src={place} alt="rastreo" />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem' }}>
            <SectionSubTitle>
              Rastrea el estatus de tu envío
            </SectionSubTitle>
            <SectionDescripotion>
              Tu cliente y tú podrán visualizar el avance de la entrega de su paquete.
            </SectionDescripotion>
          </div>
        </SectionDescriptionWraper>
      </SectionContainer>
      <SectionImg src={Tracking} srcset={`${TrackingM} 900px`} alt="tracking" />
      <SectionImgM src={TrackingM} alt="tracking" />
    </SectionWraper>
  );
}

export default ThirdSection;
