import React from 'react';
import styled from 'styled-components';
import { FaLeaf } from 'react-icons/fa';
import { eiyaColor as colors } from '../../../utils/colors';
import Bike from '../../../Images/Home/home-sustentability.png';
import Cacahuate from '../../../Images/cacahuates.png';
import arrow from '../../../Images/arrow.svg';
import box from '../../../Images/bi-box.svg';
import growth from '../../../Images/growth.svg';
import carbon from '../../../Images/carbon.svg';

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${window.innerHeight}px;
  background: url(${Bike});
  background-size: cover;

  @media screen and (max-width: 900px) {
    background-repeat: no-repeat;
    background-position-x: center;
    height: 100%;
  }
`;
const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: ${window.innerHeight}px;
  padding: 4rem 9rem;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Cacahuate});
  background-size: cover;

  @media screen and (max-width: 1430px) {
    padding: 4rem 6rem;
  }

  @media screen and (max-width: 1330px) {
    padding: 4rem 3rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 4rem 1rem;
    height: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
  }

  @media screen and (max-width: 1170px) {
    padding: 3rem 1rem;
    height: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
  }

  @media screen and (max-width: 900px) {
    background-repeat: no-repeat;
    background-position-x: center;
    padding: 3rem 1rem;
    height: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
  }
`;

const LeafSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: end;

  @media screen and (max-width: 900px) {
    align-self: center;
  }
`;

const LeafSectionContent = styled.div`
  margin-left: 2rem;
`;

const LeafSectionInfo = styled.div`
  color: ${colors.white};
  font-size: 24px;
  font-weight: 500;
  lineheight: 28.8px;

  @media screen and (max-width: 900px) {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const SectionTitle = styled.div`
  font-family: Lato;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.white};
  width: 70%;

  @media screen and (max-width: 900px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    width: 100%;
    margin: 2rem 0;
  }
`;

const SectionDescriptionWraper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${colors.white};

  @media screen and (max-width: 900px) {
    width: unset;
    flex-direction: column;
    margin-top: 1rem;
  }

  
`;

const SectionDescriptionContent = styled.div`
  width: 350px;
  display: flex;

  @media screen and (max-width: 1170px) {
    width: auto;
  }

  @media screen and (max-width: 900px) {
    width: 300px;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 300px) {
    width: unset;
    margin-bottom: 1rem;
  }
`;

const SectionImageDescriptionImage = styled.img`
  margin-right: 2rem;

  @media screen and (max-width: 900px) {
    width: 15%;
  }
`;

const SectionImageDescriptionContent = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;

  @media screen and (max-width: 900px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export default function SixthSection() {
  return (
    <BackgroundContainer id="ecologiclink">
      <Background>
        <LeafSectionContainer>
          <img src={arrow} alt="arrow" />
          <LeafSectionContent>
            <FaLeaf style={{ color: colors.greenHit, fontSize: '28px' }} />
            <LeafSectionInfo>~50% de entregas libre de contaminación</LeafSectionInfo>
          </LeafSectionContent>
        </LeafSectionContainer>
        <SectionTitle>
          Trabajamos diariamente en reducir nuestra huella de carbono, haciendo envíos con material
          reutilizable, empaques retornables y entregas en bicicleta; llegando cada vez más rápido a
          tus clientes <br />
          <span
            style={{
              color: colors.greenHit,
            }}
          >
            sin contaminar nuestro planeta.
          </span>
        </SectionTitle>
        <SectionDescriptionWraper>
          <SectionDescriptionContent>
            <SectionImageDescriptionImage src={box} alt="box" />
            <SectionImageDescriptionContent>
              + de 1 millón de envíos realizados
            </SectionImageDescriptionContent>
          </SectionDescriptionContent>
          <SectionDescriptionContent>
            <SectionImageDescriptionImage src={carbon} alt="carbon" />
            <SectionImageDescriptionContent>
              + de 100 toneladas de CO2 ahorrados
            </SectionImageDescriptionContent>
          </SectionDescriptionContent>
          <SectionDescriptionContent>
            <SectionImageDescriptionImage src={growth} alt="growth" />
            <SectionImageDescriptionContent>
              + de 500 ecommerce creciendo con Eiya
            </SectionImageDescriptionContent>
          </SectionDescriptionContent>
        </SectionDescriptionWraper>
      </Background>
    </BackgroundContainer>
  );
}
