import styled from 'styled-components';
import { Link } from 'react-scroll';
import { eiyaColor as colors } from '../utils/colors';

export const Button = styled(Link)`
  border-radius: 28px;
  background: ${colors.eiyaPurple};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.white};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.eiyaPurple};
    color: ${colors.lightPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
  
`;

export const ButtonAPurple = styled.a`
  border-radius: 28px;
  background: ${colors.eiyaPurple};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.white};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.eiyaPurple};
    color: ${colors.lightPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ButtonBPurple = styled.button`
  border-radius: 28px;
  background: ${colors.eiyaPurple};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.white};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.eiyaPurple};
    color: ${colors.lightPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ButtonLYellow = styled(Link)`
  border-radius: 28px;
  background: ${colors.accentYellow};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.darkPurple};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.accentYellow};
    color: ${colors.eiyaPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
  
`;

export const ButtonAYellow = styled.a`
  border-radius: 28px;
  background: ${colors.accentYellow};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.darkPurple};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.accentYellow};
    color: ${colors.eiyaPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ButtonBYellow = styled.button`
  border-radius: 28px;
  background: ${colors.accentYellow};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.darkPurple};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.accentYellow};
    color: ${colors.eiyaPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ButtonLWhite = styled(Link)`
  border-radius: 28px;
  background: ${colors.white};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.eiyaPurple};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.white};
    color: ${colors.darkPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
  
`;

export const ButtonAWhite = styled.a`
  border-radius: 28px;
  background: ${colors.white};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.eiyaPurple};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.white};
    color: ${colors.darkPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ButtonBWhite = styled.button`
  border-radius: 28px;
  background: ${colors.white};
  white-space: nowrap;
  padding: 9px 20px 10px;
  color: ${colors.eiyaPurple};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.white};
    color: ${colors.darkPurple};
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }
`;
