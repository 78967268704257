/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-computed-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import { styled as MUIStyled, createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import tinyBox from '../../../Images/tinyBox.png';
import { eiyaColor as colors } from '../../../utils/colors';

const SectionWraper = styled.div`
  background: ${colors.ligthGrey};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 9rem;

  @media screen and (max-width: 1430px) {
    padding: 4rem 6rem;
    height: auto;
  }

  @media screen and (max-width: 1330px) {
    padding: 4rem 3rem;
    height: auto;
  }

  @media screen and (max-width: 1230px) {
    padding: 4rem 1rem;
    height: auto;
  }

  @media screen and (max-width: 1170px) {
    padding: 3rem 1rem;
    height: auto;
  }

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: 100%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
  }
`;

const TinyBod = styled.img`
  width: 10%;
  position: relative;
  left: 76%;
  bottom: 240px;

  @media screen and (max-width: 1330px) {
    width: 15%;
  }

  @media screen and (max-width: 1230px) {
    width: 15%;
  }

  @media screen and (max-width: 1170px) {
    width: 15%;
  }

  @media screen and (max-width: 900px) {
    position: initial;
    bottom: unset;
    left: unset;
    align-self: center;
    width: 30%;
    margin-top: 2rem;
  }
`;

const Title = styled.p`
  color: ${colors.eiyaPurple};
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 3rem;
  align-self: center;

  @media screen and (max-width: 900px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const QuestionTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  @media screen and (max-width: 900px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
`;

/*const QuestionBullet = styled.span`
  font-size: 22px; 
  font-weight: 600;
  color: ${colors.eiyaPurple};
  margin: 0 1rem;

  @media screen and (max-width: 900px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
`;*/

const RowBullet =styled.div`
  display: flex;
`;

const QuestionAnswer = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 75%;

  @media screen and (max-width: 900px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    width: 100%;
  }
`;

const QuestionAnswerLink = styled.a`
  color: ${colors.eiyaPurple};
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: 900px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }
`;

const OtherQuestions = styled.p`
  color: ${colors.textBody};
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 3rem;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
`;

const OtherQuestionsLink = styled.a`
  color: ${colors.eiyaPurple};
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-decoration: none;
  margin-left: 0.4rem;

  @media screen and (max-width: 900px) {
    margin-left: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }
`;

const customTheme = createTheme({
  typography: {
    fontFamily: 'Lato',
    fontWeight: '500',
    fontSize: 17,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lato';
          font-size: '20px';
          font-weight: 500;
          color: colors.textDark,
          src: local('Lato'), local('Lato-Regular'), url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'}) format('lato');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        background: ${colors.ligthGrey}
      `,
    },
  },
});

const Accordion = MUIStyled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
  // eslint-disable-next-line no-unused-vars
))(({ theme }) => ({
  background: colors.ligthGrey,
  borderBottom: `2px solid #eee`,
  '&:not(:last-child)': {
    borderBottom: `2px solid #eee`,
  },
  '&:before': {
    borderBottom: `2px solid #eee`,
  },
}));

const AccordionSummary = MUIStyled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '18px', color: colors.eiyaPurple }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? colors.ligthGrey : colors.ligthGrey,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '1rem 0',
    marginLeft: '2rem',
  },
}));

const AccordionDetails = MUIStyled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '2px solid #eee',
  background: colors.ligthGrey,

  ['@media (max-width:1100px)']: {
    width: '80%'
  },
  ['@media (max-width:900px)']: {
    width: '100%'
  }
}));

export default function Questions() {
  const [expanded, setExpanded] = useState('false');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <SectionWraper>
      <SectionContainer>
        <Title>
          Preguntas frecuentes
        </Title>
        <ThemeProvider theme={customTheme}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <QuestionTitle>
                ¿Cuánto tiempo tardan en entregar mis paquetes?
              </QuestionTitle>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: '2rem' }}>
              <Typography>
                <QuestionAnswer>
                  Nuestro tiempo de entrega depende del tipo de zona
                  <RowBullet style={{ marginTop: '10px' }}>
                    <span
                        style={{
                        fontSize: '12px',
                        color: colors.eiyaPurple,
                        margin: '0 0.5rem 0 1rem',
                        verticalAlign: 'text-bottom',
                        }}
                    >
                        <CircleRoundedIcon style={{ fontSize: '10px' }} />
                    </span>
                    <div>
                      Zona Local: Dentro de la misma ciudad con cobertura Eiya, las entregas son el mismo 
                      día o el día siguiente después de recolectado. Está disponible dentro de CDMX y ZM, 
                      Toluca y Guadalajara (aplican restricciones).
                    </div>
                  </RowBullet>
                  <RowBullet>
                    <span
                        style={{
                        fontSize: '12px',
                        color: colors.eiyaPurple,
                        margin: '0 0.5rem 0 1rem',
                        verticalAlign: 'text-bottom',
                        }}
                    >
                        <CircleRoundedIcon style={{ fontSize: '10px' }} />
                    </span>
                    <div>
                      Zona Regional: De ciudad a ciudad con cobertura Eiya, entregas de 1 a 3 días hábiles
                      después de recolectado.
                    </div>
                  </RowBullet>
                  <RowBullet>
                    <span
                        style={{
                        fontSize: '12px',
                        color: colors.eiyaPurple,
                        margin: '0 0.5rem 0 1rem',
                        verticalAlign: 'text-bottom',
                        }}
                    >
                        <CircleRoundedIcon style={{ fontSize: '10px' }} />
                    </span>
                    <div>
                      Zona Nacional: Al resto del país, entregas de 1 a 3 días hábiles, son operados por 
                      paqueterías aliadas con costo preferencial, Eiya no tiene control sobre las entregas 
                      nacionales. Puedes revisar más información sobre   
                      <QuestionAnswerLink
                        href="https://ayuda.eiya.mx/hc/es-mx/articles/1500002074141-Tarifas-y-Cobertura-Nacional-mapa-"
                      >
                        {' Tarifas y Cobertura Nacional.'}
                      </QuestionAnswerLink>
                    </div>
                  </RowBullet>
                </QuestionAnswer>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <QuestionTitle>
                ¿Qué pasa si no logran entregar mi paquete?
              </QuestionTitle>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: '2rem' }}>
              <Typography>
                <QuestionAnswer>
                  <RowBullet>
                    <span
                        style={{
                        fontSize: '12px',
                        color: colors.eiyaPurple,
                        margin: '0 0.5rem 0 1rem',
                        verticalAlign: 'text-bottom',
                        }}
                    >
                        <CircleRoundedIcon style={{ fontSize: '10px' }} />
                    </span>
                    <div>
                      Envíos locales y regionales: Ofrecemos dos intentos de entrega para cada guía. 
                      Si el primer intento no fue exitoso, el paquete será devuelto a Eiya y será preparado 
                      para realizar un segundo intento contactando al destinatario. Si un segundo intento no 
                      fue exitoso, Eiya devolverá el paquete al negocio respectivo o, de solicitarlo, se 
                      resguardará para un tercer intento de entrega (aplican costos, después del segundo 
                      intento fallido).
                    </div>
                  </RowBullet>
                  <RowBullet>
                    <span
                        style={{
                        fontSize: '12px',
                        color: colors.eiyaPurple,
                        margin: '0 0.5rem 0 1rem',
                        verticalAlign: 'text-bottom',
                        }}
                    >
                        <CircleRoundedIcon style={{ fontSize: '10px' }} />
                    </span>
                      <div>
                        Envíos nacionales: Aplican las políticas de la paquetería en uso. Las paqueterías 
                        realizan hasta dos intentos de ser posible para entregar el paquete. Si no es posible 
                        entregar al destinatario, se devolverá a la dirección de origen.
                      </div>
                  </RowBullet>
                </QuestionAnswer>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <QuestionTitle>
                ¿Cómo funcionan las recolectas?
              </QuestionTitle>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: '2rem' }}>
              <Typography>
                <QuestionAnswer>     
                  Agenda tus recolectas en tu plataforma. En la sección de recolectas puedes agendar el día de la 
                  recolección, si no recolectamos el día que solicitaste automáticamente recolectamos el día 
                  siguiente. En todo momento puedes dar seguimiento el estatus de tu recolecta en la plataforma o 
                  por nuestros canales de soporte.
                  <div style={{ marginTop: '10px' }}/>
                  Toma en cuenta que la exactitud en la hora de recolecta depende de diversos factores como volumen 
                  de paquetes, recurrencia de uso del servicio y código postal de recolecta. Debes solicitar tu 
                  recolecta únicamente hasta que tengas los paquetes listos y así evitar costos de cancelación.
                </QuestionAnswer>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <QuestionTitle>
                ¿Cómo recargar saldo?
              </QuestionTitle>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: '2rem' }}>
              <Typography>
                <QuestionAnswer>
                Recarga saldo a tu cuenta de Eiya ocupando los métodos de pago disponibles en 
                plataforma:
                <RowBullet style={{ marginTop: '10px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: colors.eiyaPurple,
                      margin: '0 0.5rem 0 1rem',
                      verticalAlign: 'text-bottom',
                    }}
                  >
                    <CircleRoundedIcon style={{ fontSize: '10px' }} />
                  </span>
                  <div>Transferencia bancaria. Se te asigna una CLABE única para tu cuenta.</div>
                </RowBullet>
                <RowBullet style={{ marginBottom: '10px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: colors.eiyaPurple,
                      margin: '0 0.5rem 0 1rem',
                      verticalAlign: 'text-bottom',
                    }}
                  >
                    <CircleRoundedIcon style={{ fontSize: '10px' }} />
                  </span>
                  <div>Pago con tarjetas bancarias.</div>
                </RowBullet>
                El monto transferido se refleja minutos después en el saldo de tu cuenta. Los montos 
                transferidos no son reembolsables.
                </QuestionAnswer>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <QuestionTitle>
                ¿Cómo puedo facturar?
              </QuestionTitle>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: '2rem' }}>
              <Typography>
                <QuestionAnswer>
                  Las facturas son opcionales y pueden ser emitidas de manera automática en cada
                  transferencia realizada o se pueden generar manualmente dentro de los próximos 30
                  días naturales posteriores a la transferencia. Para facturar es necesario ingresar
                  los datos fiscales correctos en tu portal, puedes consultar
                  <QuestionAnswerLink
                    href="https://ayuda.eiya.mx/hc/es-mx/articles/360054565054-Pagos-y-facturaci%C3%B3n"
                  >
                    {' más información aquí.'}
                  </QuestionAnswerLink>
                </QuestionAnswer>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
        <OtherQuestions>
          ¿Tienes más preguntas?, consulta nuestro{' '}
          <OtherQuestionsLink href="https://ayuda.eiya.mx/hc/es-mx">
            Centro de Ayuda.
          </OtherQuestionsLink>
        </OtherQuestions>
      </SectionContainer>
      <TinyBod src={tinyBox} alt="carrusel" />
    </SectionWraper>
  );
}
