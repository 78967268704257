import React from 'react';
import styled from 'styled-components';
import Shop from '../../../Images/Shipping/envios-integraciones.png';
import { eiyaColor as colors } from '../../../utils/colors';

const innerHeight = window.innerHeight + 300;
const realHeight = `${innerHeight}px`;

const SectionWraper = styled.div`
  background: ${colors.white};
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;

  @media screen and (max-width: 1650px) {
    height: ${window.innerHeight}px;
  }

  @media screen and (max-width: 1430px) {
    height: ${window.innerHeight-300}px;
  }

  @media screen and (max-width: 1330px) {
    padding: 0;
    height: ${window.innerHeight}px;
  }

  @media screen and (max-width: 1230px) {
    margin-top: 6rem;
    padding: 0;
    height: ${window.innerHeight}px;
  }

  @media screen and (max-width: 1170px) {
    padding: 0;
    height: ${window.innerHeight}px;
  }

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: 100%;
    margin-top: 0;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
    justify-content: space-between; 
  }

  @media screen and (max-width: 1170px) {
    padding-top: 0;
    justify-content: space-between; 
  }

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const SectionImg = styled.img`
  width: 45%;
  margin-left: 3rem;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
    margin-left: 1rem;
  }

  @media screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%;
    margin-top: 3rem;
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 6rem;
  width: 25%;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
    margin: 0 2rem;
    width: 45%;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    width: 100%;
  }
`;

const SectionTitle = styled.p`
  font-size: 32px;
  color: ${colors.eiyaPurple};
  font-weight: 700;
  line-height: 38.4px;
  margin-bottom: 3rem;

  @media screen and (max-width: 900px) {
    margin: 0 auto 2rem;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
  }
`;

const SectionDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  color: ${colors.textBody};
  margin-left: 2rem;

  @media screen and (max-width: 900px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;z
  }
`;

function FifthSection() {
  return (
    <SectionWraper>
      <SectionContainer>
        <SectionImg src={Shop} alt="integraciones" />
        <SectionContent>
          <SectionTitle>Conecta tu tienda en línea y crea hasta 50 guías a la vez</SectionTitle>
          <SectionDescription>
            <ul>
              <li>Sincroniza tus pedidos y ahorra tiempo al crear 50 guías al mismo tiempo.</li>
              <li>
                Conecta cualquier plataforma con nuestra API fácil de usar para desarrolladores.
              </li>
            </ul>
          </SectionDescription>
        </SectionContent>
      </SectionContainer>
      <div id="deliverylink" />
    </SectionWraper>
  );
}

export default FifthSection;
