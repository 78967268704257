import React from 'react';
import HeroSection from './HeroSection';
import TrackingComponent from './TrackingComponent';
import HomeSection from './InfoSection';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FifthSection from './FifthSection';
import SixthSection from './SixthSection';
import SeventhSection from './SeventhSection';
import Questions from './Questions';
import AditionalInfo from './AdditionalInfo';
import MissionSection from './MissionSection';

function InfoSection() {
  return (
    <>
      <HeroSection />
      <TrackingComponent />
      <HomeSection />
      <MissionSection />
      <FifthSection />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <SixthSection />
      <SeventhSection />
      <Questions />
      <AditionalInfo />
    </>
  );
}
export default InfoSection;
