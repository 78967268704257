import React from 'react';
import styled from 'styled-components';
import { eiyaColor as colors } from '../../../utils/colors';
import background from '../../../Images/Messenger/messenger-footer.png';
import google from '../../../Images/Messenger/googlePlay-button.png';

const BackgroundGradient = styled.div`
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  padding: 0 9rem;

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: auto;
  }
`;

export default function AditionalInfo() {
  return (
    <BackgroundGradient>
      <p
        style={{
          fontSize: '24px',
          color: colors.white,
          fontWeight: '800',
          marginBottom: '2rem',
        }}
      >
        Descarga la aplicación y regístrarte
      </p>
      <a
        href="https://play.google.com/store/apps/details?id=com.eiyamessengerapp&hl=es_MX"
        target="_blank"
        rel="noreferrer"
      >
        <img src={google} alt="google play button" style={{ width: '30%' }} />
      </a>
    </BackgroundGradient>
  );
}
