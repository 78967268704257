import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../Components/Footer';
import HomeSection from '../../Components/Home';
/* import HomeInfoSection from '../../Components/Home/InfoSection'; */
import HomeNavbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.size) {
      const timestamp = new Date().getTime();
      const stringParams = searchParams.toString();
      localStorage.setItem("campaignData", stringParams + `&timestamp_campaign=${timestamp}`);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          name="keywords"
          content="Envíos para ecommerce, Plataforma de envíos para ecommerce, Paquetería para ecommerce, Servicio de paquetería para ecommerce, Empresas de paquetería para ecommerce"
        />
        <meta
          charSet="utf-8"
          name="description"
          content="Eiya es una plataforma especializada en envíos para ecommerce. Si buscas almacenaje y servicio de paquetería para tu tienda en línea Eiya es para ti."
        />
        <title>Servicio de Mensajería, Paquetería y Envíos para Ecommerce - Eiya</title>
        <link rel="canonical" href="https://eiya.mx/envios" />
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HomeNavbar isOpen={isOpen} toggle={toggle} />
      <HomeSection />
      {/* 
        <HomeInfoSection/> */}
      <Footer />
    </>
  );
}

export default Home;
