/* eslint-disable import/prefer-default-export */
export const eiyaColor = {
  /* Primary */
  lightPurple: '#CCBCE8',
  eiyaPurple: '#8058C7',
  darkPurple: '#64499E',
  babyYellow: '#FBF0BE',
  accentYellow: '#FFD100',
  brownYellow: '#CBA200',
  /* Background */
  white: ' #FFFFFF',
  faWhite: ' #FAFAFA',
  dirtyWhite: '#F5F5F5',
  ligthGrey: '#EEEEEE',
  grey: '#CCCCCC',
  purplite: '#F5F6FF',
  generalDark: '#2B3238',
  blackHighlight: '#24282D',
  /* Text */
  textDark: '#424242',
  textBody: '#757575',
  leyendas: '#959595',
  /* Alert */
  lightGreen: '#C8EEDB',
  greenHit: '#4CD964',
  redBug: '#FF1744',
  /* Button */
  transparent: '#FFFFFF00',
  disabled: '#EAE7F9',
  redBugLight: '#FF17441A',
  greenWA: '#25d366'
};
