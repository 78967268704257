import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';
/* import { Link as LinkS } from 'react-scroll';
 */ import { eiyaColor as colors } from '../../utils/colors';
import { ButtonLWhite, ButtonLYellow } from '../ButtonElement';

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: ${colors.eiyaPurple};
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2rem;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
  color: ${colors.accentYellow};
`;

export const Icon = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWraper = styled.div`
  color: ${colors.accentYellow};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
`;

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: baseline;

  @media screen and (max-width: 900px) {
  }
`;

export const SidebarLink = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: ${colors.white};
  cursor: pointer;
  margin: 15px 0;

  &:hover {
    color: ${colors.white};
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarLnk = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: ${colors.white};
  cursor: pointer;
  margin: 15px 0;

  &:hover {
    color: ${colors.white};
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarRoute = styled(LinkR)`
  border-radius: 50px;
  background: ${colors.eiyaPurple};
  textdark-space: nowrap;
  padding: 16px 64px;
  color: ${colors.white};
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: 0.2s ease-in-out;
    background: ${colors.eiyaPurple};
    color: ${colors.white};
  }
`;

export const SidebarBtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 25vh;
`;

export const YellowButton = styled(ButtonLYellow)`
  margin-top: 1rem;
  width: 100%;
`;

export const WhiteButton = styled(ButtonLWhite)`
  background: ${colors.transparent};
  border: 1px solid ${colors.white};
  color: ${colors.white};
  width: 100%;
`;
