import React, { useState } from 'react';
import Footer from '../../Components/Footer';
import AboutInfoSection from '../../Components/About';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';

function About() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar isOpen={isOpen} toggle={toggle} />
      <AboutInfoSection />
      <Footer />
    </>
  );
}

export default About;
