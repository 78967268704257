import React, { useState } from 'react';
import Footer from '../../Components/Footer';
import MessengerSection from '../../Components/Messenger';
/* import MessengerInfoSection from '../../Components/Messenger/InfoSection';
 */ import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';

function Messenger() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar isOpen={isOpen} toggle={toggle} />
      <MessengerSection />
      {/* 
        <MessengerInfoSection/> */}
      <Footer />
    </>
  );
}

export default Messenger;
