import React from 'react';
import HeroSection from './HeroSection';
import FirstSection from './FirstSection'; /* 
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection'; */
import AditionalInfo from './AdditionalInfo';

function InfoSection() {
  return (
    <>
      <HeroSection />
      <FirstSection />
      {/* 
      <SecondSection />
      <ThirdSection /> */}
      <AditionalInfo />
    </>
  );
}
export default InfoSection;
