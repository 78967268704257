import React from 'react';
import styled from 'styled-components';
import MessengerImg from '../../../Images/Messenger/messenger-header.png';
import google from '../../../Images/Messenger/googlePlay-button.png';
import { eiyaColor as colors } from '../../../utils/colors';

const { innerHeight } = window;
const realHeight = `${innerHeight}px`;

const BackgroundImage = styled.div`
  background: ${colors.white};
  background-image: url(${MessengerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  height: ${realHeight};
  position: relative;
  z-index: 1;

  @media screen and (max-width: 900px) {
    background-position-x: unset;
    background-position-y: bottom;
    padding-top: 5rem;
    padding-bottom: 8rem;
    height: auto;
  }
`;

const BackgroundGradient = styled.div`
  background: linear-gradient(90deg, #ffffff 45%, transparent 65%);
  height: ${realHeight};
  width: 100%;

  @media screen and (max-width: 900px) {
    background: linear-gradient(170.59deg, #ffffff 55.62%, rgba(255, 255, 255, 0) 89.09%);
    height: 100%;
    padding: 4rem 1rem;
  }
`;

const SectionWraper = styled.div`
  width: 50%;
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding-left: 9rem;

  @media screen and (max-width: 900px) {
    padding-left: 0;
    height: 100%;
    width: 100%;
  }
`;

const SectionContentTitle = styled.p`
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 4rem;

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;
  }
`;

const SectionContentSubTitle = styled.p`
  color: ${colors.textBody};
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2rem;
  width: 70%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const SectionButtonText = styled.p`
  color: ${colors.textBody};
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 3rem;
  width: 70%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const GoogleButton = styled.img`
  width: 30%;

  @media screen and (max-width: 900px) {
    width: 50%;
  }
`;

function HeroSection() {
  return (
    <BackgroundImage>
      <BackgroundGradient>
        <SectionWraper>
          <SectionContentTitle>
            Únete al mejor <span style={{ color: colors.eiyaPurple }}>equipo</span> de mensajeros
          </SectionContentTitle>
          <SectionContentSubTitle>
            Gana dinero entregando paquetes en toda la ciudad utilizando tu bicicleta o automóvil.
          </SectionContentSubTitle>
          <SectionButtonText>Para registrarte, descarga la aplicación:</SectionButtonText>

          <a
            href="https://play.google.com/store/apps/details?id=com.eiyamessengerapp&hl=es_MX"
            target="_blank"
            rel="noreferrer"
          >
            <GoogleButton src={google} alt="google play button" />
          </a>
        </SectionWraper>
      </BackgroundGradient>
    </BackgroundImage>
  );
}

export default HeroSection;
