import React from 'react';
import styled from 'styled-components';
import background from '../../../Images/about-vector.png';
import bike from '../../../Images/About/conocenos-proposito.png';
import people from '../../../Images/About/conocenos-valores.png';
import smile from '../../../Images/smile-icon.svg';
import graduate from '../../../Images/graduate-icon.svg';
import heart from '../../../Images/heart-icon.svg';
import team from '../../../Images/About/conocenos-collage.png';
import { eiyaColor as colors } from '../../../utils/colors';

const BackgroundImage = styled.div`
  background: ${colors.white};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  width: 100%;
  z-index: 1;
  padding: 0 9rem;
  color: ${colors.textDark};

  @media screen and (max-width: 900px) {
    padding: 0 1rem;
    background-image: none;
  }
`;

const Image = styled.img`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  margin-bottom: 9rem;
  background: ${colors.white};

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;

  }
`;

const height = window.innerHeight;
const realHeight = `${height}px`;

const FirstSectionWraper1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${realHeight};
  
  @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 2rem;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const ImageDescription = styled.img`
  width: 40%;
  
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 2rem 0;
  }
`;

const FirstSectionContentWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width:45%;

  @media screen and (max-width: 900px) {
    width:100%;
  }
`;

const FirstSectionContentTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.eiyaPurple};
  margin-bottom: 1rem;

  @media screen and (max-width: 900px) {
  }
`;

const FirstSectionContentDescription = styled.p`
  font-size: 20px;
  font-weight: 500;
  width: 60%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const FirstSectionWraper2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${realHeight};
  
  @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 2rem;
    flex-direction: column;
    justify-content: center;
  }
`;

const FirstSectionContentDescription2 = styled.p`
  font-size: 20px;
  font-weight: 500;
  width: 70%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const FirstSectionWraper3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${realHeight};
  
  @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 2rem;
    
  }
`;

const FirstSectionContentWraper3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  
  @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 2rem;
    
  }
`;

const FirstSectionContentTitle3 = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: ${colors.accentYellow};
  background: ${colors.white};
  margin-bottom: 2rem;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

const FirstSectionContentDescription3 = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4rem;
  text-align: center;
  background: ${colors.white};
  color: ${colors.textDark};
  width: 60%;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-bottom: 2rem;
}
`;

const FirstSectionContent3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${colors.white};
  margin-bottom: 4rem;

  @media screen and (max-width: 900px) {
    height: auto;
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const FirstSectionWraperContent3 = styled.div`
  width: 30%;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
`;

const FirstSectionContentTitl3 = styled.p`
  width: 50%;
  font-size: 18px;
  font-weight: 600;
  margin: 1rem 0;
  color: ${colors.textDark};

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-left: 1rem; 
  }
`;

const FirstSectionContentDesc3 = styled.p`
  max-width: 70%;
  font-size: 14px;
  font-weight: 400;
  margin: 1rem 0;
  color: ${colors.textBody};

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const FirstSectionWraperMobile = styled.div`
  
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: row;
  }
`;

const FirstSectionContentDescription4 = styled.p`
  width: 50%;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3rem;
  text-align: center;
  color: ${colors.eiyaPurple};
  background: ${colors.white};
  
  @media screen and (max-width: 900px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

function FirstSection() {
  return (
    <BackgroundImage>
      <FirstSectionWraper1>
        <ImageDescription src={bike} alt="bici" />
        <FirstSectionContentWraper>
          <FirstSectionContentTitle>
            Nuestro propósito
          </FirstSectionContentTitle>
          <FirstSectionContentDescription>
            Impulsar el comercio electrónico y llegar cada vez más rápido a tus clientes,
            disminuyendo la contaminación de nuestro planeta, a través de más del 50% de envíos
            sustentables, es una de nuestras estrellas norte.
          </FirstSectionContentDescription>
        </FirstSectionContentWraper>
      </FirstSectionWraper1>
      <FirstSectionWraper2>
        <FirstSectionContentWraper>
          <FirstSectionContentTitle>
            Valores principales
          </FirstSectionContentTitle>
          <FirstSectionContentDescription2
            style={{
              marginBottom: '1rem'
            }}
          >
            Queremos construir un tipo diferente de empresa que se centre no sólo en los resultados
            finales, sino también en la felicidad de nuestros clientes y equipo, y en nuestro
            crecimiento personal a lo largo del viaje.
          </FirstSectionContentDescription2>
          <FirstSectionContentDescription2

          >
            Estos son los valores que guían colectivamente nuestras decisiones y acciones.
          </FirstSectionContentDescription2>
        </FirstSectionContentWraper>
        <ImageDescription src={people} alt="eiya" style={{ background: colors.white }} />
      </FirstSectionWraper2>
      <FirstSectionWraper3>
        <FirstSectionContentWraper3>
          <FirstSectionContentTitle3>
            ¡Amamos lo que hacemos!
          </FirstSectionContentTitle3>
          <FirstSectionContentDescription3>
            Estamos comprometidos a construir un equipo diverso y un entorno de trabajo incluyente,
            por ello nuestra cultura es sencilla. Trabaja duro y por qué no divertirte mientras
            tanto.
          </FirstSectionContentDescription3>
          <FirstSectionContent3>
            <FirstSectionWraperContent3>
              <FirstSectionWraperMobile>
                <img src={heart} alt="corazon" />
                <FirstSectionContentTitl3>
                  Libertad y responsabilidad
                </FirstSectionContentTitl3>
              </FirstSectionWraperMobile>
              <FirstSectionContentDesc3>
                Creemos en metas y entregables, no en horario a cumplir. Creemos en el trabajo
                remoto y horas flexibles de trabajo.
              </FirstSectionContentDesc3>
            </FirstSectionWraperContent3>
            <FirstSectionWraperContent3>
              <FirstSectionWraperMobile>
                <img src={graduate} alt="educacion" />
                <FirstSectionContentTitl3>
                  Educación y crecimiento
                </FirstSectionContentTitl3>
              </FirstSectionWraperMobile>
              <FirstSectionContentDesc3>
                Queremos que nuestros empleados crezcan con nosotros. Ofrecemos planes de
                crecimiento y acceso a diversas plataformas de educación online
              </FirstSectionContentDesc3>
            </FirstSectionWraperContent3>
            <FirstSectionWraperContent3>
              <FirstSectionWraperMobile>
                <img src={smile} alt="diversion" />
                <FirstSectionContentTitl3>
                  Integración y diversión
                </FirstSectionContentTitl3>
              </FirstSectionWraperMobile>
              <FirstSectionContentDesc3>
                Conoce al resto del equipo, organizamos eventos para fomentar la convivencia entre áreas y compañeros.
              </FirstSectionContentDesc3>
            </FirstSectionWraperContent3>
          </FirstSectionContent3>
          <FirstSectionContentDescription4>
            Conoce al maravilloso equipo que está construyendo nuestro producto, apoyando a nuestros
            clientes y entregando tus pedidos.
          </FirstSectionContentDescription4>
        </FirstSectionContentWraper3>
      </FirstSectionWraper3>

      <Image
        src={team}
        alt="eiya team"
      />
    </BackgroundImage>
  );
}

export default FirstSection;
