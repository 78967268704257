/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';
import ShippingImg from '../../../Images/Home/home-background.png';
import ShippingImgM from '../../../Images/Home/home-background-mobile.png';
import { eiyaColor as colors } from '../../../utils/colors';

const { innerHeight } = window;

const BackgroundImage = styled.div`
  background: ${colors.white};
  background-image: url(${ShippingImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: ${innerHeight-120}px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    background-image: url(${ShippingImgM});
    background-size: contain;
    background-position-x: right;
    padding-bottom: 8rem;
    height: auto;
  }

  @media screen and (max-width: 530px) {
    background-position-y: bottom;
      background-position-x: right;
  }
`;

const BackgroundGradient = styled.div`
  background: linear-gradient(90deg, #ffffff 45%, transparent 65%);
  height: max-content;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    background: linear-gradient(170.59deg, #ffffff 55.62%, rgba(255, 255, 255, 0) 89.09%);
    height: 100%;
    padding: 4rem 1rem;
  }

  @media screen and (max-width: 500px) {
    padding: 2rem 1rem 4rem 1rem;
  }
`;



const SectionWraper = styled.div`
  width: 50%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  padding: ${window.innerHeight / 8}px 0 0 9rem;

  @media screen and (max-width: 1430px) {
    padding-left: 6rem;
  }

  @media screen and (max-width: 1330px) {
    padding-left: 3rem;
    padding-top: ${window.innerHeight / 14}px;
  }

  @media screen and (max-width: 1230px) {
    padding-left: 3rem;
  }

  @media screen and (max-width: 1170px) {
    padding-left: 1rem;
  }

  @media screen and (max-width: 900px) {
    padding: 0;
    height: 100%;
    width: 100%;
  }
`;

const SectionContentTitle = styled.p`
  font-size: 48px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: ${window.innerHeight / 15}px;

  @media screen and (max-width: 1430px) {
    margin-bottom: ${window.innerHeight / 20}px;
  }

  @media screen and (max-width: 1330px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;
    font-size: 24px;
    font-weight: 800;
    line-height: 29px;
  }
`;

const SectionContentSubTitle = styled.p`
  color: ${colors.textBody};
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 4rem;
  width: 80%;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
    width: 100%;
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
    width: 90%;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
`;

function HeroSection() {
  return (
    <BackgroundImage>
      <BackgroundGradient>
        <SectionWraper>
          <SectionContentTitle>
            ¡Pedaleamos la última milla por ti!
            <br />
            <span
              style={{
                color: colors.eiyaPurple,
              }}
            >
              Envíos
            </span>{' '}
            más
            <span
              style={{
                color: colors.eiyaPurple,
              }}
            >
              {' rápidos '}
              
            </span>
            para tu ecommerce.
          </SectionContentTitle>
          <SectionContentSubTitle id="pricelink">
            Únete a las más de 2000 marcas que confían en nuestro servicio. Somos tu aliado para 
            brindar la mejor experiencia de compra a sus clientes.
          </SectionContentSubTitle>
        </SectionWraper>
      </BackgroundGradient>
    </BackgroundImage>
  );
}

export default HeroSection;
