/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWraper,
  SidebarMenu,
  SidebarLink,
  SidebarBtnWrap,
  YellowButton,
  WhiteButton,
  SidebarLnk
} from './SidebarElements';
import Logo from '../../Images/eiya-logo-white.svg';
import { eiyaColor as colors } from '../../utils/colors';
import { getUrlCampaignData } from '../../utils/helpFunctions';

function Sidebar({ isOpen, toggle }) {
  const [isOpenDDL, setIsOpenDDL] = useState(false);

  const openDDL = () => {
    setIsOpenDDL(!isOpenDDL);
  };

  const openLogin = () => {
    window.open('https://envios.eiya.mx/login');
  };

  const openSignup = () => {
    window.open(getUrlCampaignData());
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <img src={Logo} alt="logo" style={{ marginBottom: '2rem' }} />
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWraper>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            Inicio
          </SidebarLink>
          {/* <SidebarLink to="/fulfillment" onClick={toggle}>
            Fulfillment
  </SidebarLink> */}
          <SidebarLink to="/conocenos" onClick={toggle}>
            Conocenos
          </SidebarLink>
          <SidebarLink to="/mensajeros" onClick={toggle}>
            Mensajeros
          </SidebarLink>
          <SidebarLnk onClick={openDDL}>
            Recursos <FaChevronDown style={{ marginLeft: '1rem', fontSize: '12px' }} />
          </SidebarLnk>
          {isOpenDDL ? (
            <div
              style={{
                textAlign: 'initial',
                marginLeft: '1rem',
              }}
            >
              {/* <a
                style={{ textDecoration: 'none', color: colors.white }}
                href="https://www.tienda.eiya.mx/"
                target="_blank"
                rel="noreferrer"
              >
                <p onClick={toggle} style={{ marginBottom: '10px' }}>
                  Insumos
                </p>
            </a> */}
              <a
                style={{ textDecoration: 'none', color: colors.white }}
                href="https://www.youtube.com/channel/UCdxznk4UEmDQTn2tN6Dy4JQ"
                target="_blank"
                rel="noreferrer"
              >
                <p onClick={toggle} style={{ marginBottom: '10px' }}>
                  Guías y tutoriales
                </p>
              </a>
              <a
                style={{ textDecoration: 'none', color: colors.white }}
                href="https://developers.eiya.mx/"
                target="_blank"
                rel="noreferrer"
              >
                <p onClick={toggle} style={{ marginBottom: '10px' }}>
                  Documentación API
                </p>
              </a>
              <a
                style={{ textDecoration: 'none', color: colors.white }}
                href="https://ayuda.eiya.mx/hc/es-mx"
                target="_blank"
                rel="noreferrer"
              >
                <p onClick={toggle} style={{ marginBottom: '10px' }}>
                  Centro de ayuda
                </p>
              </a>
            </div>
          ) : null}
        </SidebarMenu>
        <SidebarBtnWrap>
          <WhiteButton onClick={openLogin}>Iniciar sesión</WhiteButton>
          <YellowButton onClick={openSignup}>Regístrate</YellowButton>
        </SidebarBtnWrap>
      </SidebarWraper>
    </SidebarContainer>
  );
}

export default Sidebar;
