/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { eiyaColor as colors } from '../../../utils/colors';
import smile from '../../../Images/smile.svg';
import almaquietaLogo from '../../../Images/Brands/Shipping/almaquieta-logo.svg';
import llLogo from '../../../Images/Brands/Shipping/ll-logo.svg';
import farmapielLogo from '../../../Images/Brands/Shipping/farmapiel-logo.svg';
import lushLogo from '../../../Images/Brands/Shipping/lush-logo.svg';
import Carousel from '../../Carousel/ShippingReviews';

const SectionWraper = styled.div`
  height: ${window.innerHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  @media screen and (max-width: 900px) {
    height: 100%;
    flex-direction: column;
  }
`;

const SectionContainer = styled.div`
  margin-left: 9rem;

  @media screen and (max-width: 1430px) {
    margin-left: 6rem;
  }

  @media screen and (max-width: 1330px) {
    margin-left: 3rem;
  }

  @media screen and (max-width: 1230px) {
    margin-left: 3rem;
  }

  @media screen and (max-width: 1170px) {
    margin-top: 3rem;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media screen and (max-width: 900px) {
    margin-top: 4rem;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const SectionTitle = styled.p`
  color: ${colors.eiyaPurple};
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 1rem;

  @media screen and (max-width: 900px) {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
  }
`;

const SectionSubTitle = styled.p`
  color: ${colors.eiyaPurple};
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  margin-bottom: 1rem;

  @media screen and (max-width: 900px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }
`;

const SectionDescription = styled.p`
  color: ${colors.textBody};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 2rem 0;

  @media screen and (max-width: 900px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
`;

const SectionDescription2 = styled.p`
  color: ${colors.textBody};
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  width: 90%;
  margin-right: 2rem;

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
`;

const SmilieImg = styled.img`
  display: none;
  margin-left: 9rem;

  @media screen and (max-width: 900px) {
    display: initial;
  }
`;

const SmilieImg2 = styled.img`
  margin-left: 1rem;
  
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const BrandImageContainer = styled.div`
  margin-top: 3rem;
  margin-right: 9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1430px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: 1330px) {
    margin-right: 3rem;
  }

  @media screen and (max-width: 1230px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: 1170px) {
    margin-right: 0;
  }

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
    margin-right: 0;
    flex-direction: column;
  }
`;

const LogoImg = styled.img`
  
  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

export default function SeventhSection() {
  return (
    <SectionWraper>
      <SectionContainer>
        <SectionTitle>
          Reseñas <SmilieImg alt="carita" src={smile} />
        </SectionTitle>
        <SectionSubTitle>
          Clientes más contentos <SmilieImg2 alt="carita" src={smile} />
        </SectionSubTitle>
        <SectionDescription>
          ¿Te imaginas comprar y recibir horas, minutos después?
        </SectionDescription>
        <SectionDescription2>
          Más de 500 marcas mexicanas están deleitando a sus clientes gracias a nuestras soluciones
          integrales de{' '}
          <span style={{ fontWeight: 'bold', color: colors.textDark }}>Envíos.</span>
        </SectionDescription2>
        <BrandImageContainer>
          <LogoImg src={almaquietaLogo} alt="almaquieta" />
          <LogoImg src={llLogo} alt="libreliebre" />
          <LogoImg src={farmapielLogo} alt="farmapiel" />
          <LogoImg src={lushLogo} alt="lush" />
        </BrandImageContainer>
      </SectionContainer>
      <Carousel />
    </SectionWraper>
  );
}
