import React from 'react';
import HeroSection from './HeroSection';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
/* import Questions from './Questions';
 */ import AditionalInfo from './AdditionalInfo';

function InfoSection() {
  return (
    <>
      <HeroSection />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      {/*       <Questions />
       */}
      <AditionalInfo />
    </>
  );
}
export default InfoSection;
