import React from 'react';
import styled from 'styled-components';
import money from '../../../Images/messenger-money-icon.svg';
import people from '../../../Images/messenger-people-icon.svg';
import time from '../../../Images/messenger-time-icon.svg';
import { eiyaColor as colors } from '../../../utils/colors';

const innerHeight = window.innerHeight + 60;
const realHeight = `${innerHeight}px`;

const SectionWraper = styled.div`
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 9rem;

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: 100%;
    width: 100%;
  }
`;

const SectionTitle = styled.p`
  font-size: 32px;
  color: ${colors.textDark};
  font-weight: 700;
  margin: 2rem 0;
`;

const SectionSubTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: ${colors.textBody};
  margin-bottom: 6rem;
  text-align: center;
  width: 60%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const SectionContentWraper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const SectionContentCard = styled.div`
  background: ${colors.ligthGrey};
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 2rem;

  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 1rem;
    margin-bottom: 2rem;
  }
`;

function FirstSection() {
  return (
    <SectionWraper>
      <SectionTitle>Ciclistas y choferes con la mejor actitud y energía</SectionTitle>
      <SectionSubTitle>
        Más allá de entregar paquetes, nos enfocamos en brindar la mejor experiencia de entrega a
        través del servicio al cliente y buenas prácticas.
      </SectionSubTitle>
      <SectionContentWraper>
        <SectionContentCard>
          <img src={money} alt="dinero" />
          <p
            style={{
              fontSize: '20px',
              fontWeight: '600',
              color: colors.textDark,
              margin: '2rem 0',
            }}
          >
            Gana hasta 5 mil pesos semanales
          </p>
          <p style={{ fontSize: '18px', fontWeight: '400', color: colors.textBody }}>
            Realiza entregas en rutas cercanas a tu domicilio y disfruta de bonos especiales por
            entrega.
          </p>
        </SectionContentCard>
        <SectionContentCard>
          <img src={time} alt="tiempo" />
          <p
            style={{
              fontSize: '20px',
              color: colors.textDark,
              fontWeight: '600',
              margin: '2rem 0',
            }}
          >
            Disfruta de horarios flexibles
          </p>
          <p style={{ fontSize: '18px', fontWeight: '400', color: colors.textBody }}>
            Organiza tu tiempo para trabajar de acuerdo a tus necesidades, conectate desde tu app
            cuando esté disponible.
          </p>
        </SectionContentCard>
        <SectionContentCard>
          <img src={people} alt="personas" />
          <p
            style={{
              fontSize: '20px',
              color: colors.textDark,
              fontWeight: '600',
              margin: '2rem 0',
            }}
          >
            Eventos y reuniones
          </p>
          <p style={{ fontSize: '18px', fontWeight: '400', color: colors.textBody }}>
            Organizamos carreras para fomentar la convivencia entre mensajeros y obtener grandes
            premios.
          </p>
        </SectionContentCard>
      </SectionContentWraper>
    </SectionWraper>
  );
}

export default FirstSection;
