import React from 'react';
/* import styled from 'styled-components';
 */ import { eiyaColor as colors } from '../../../utils/colors';
import almaquieta from '../../../Images/Brands/Shipping/almaquieta.png';
import almaquietaLogo from '../../../Images/Brands/Shipping/almaquieta-logo.png';
import farmapiel from '../../../Images/Brands/Shipping/farmapiel.png';
import farmapielLogo from '../../../Images/Brands/Shipping/farmapiel-logo.png';
import lush from '../../../Images/Brands/Shipping/lush.png';
import lushLogo from '../../../Images/Brands/Shipping/lush-logo.png';
import ll from '../../../Images/Brands/Shipping/ll.png';
import llLogo from '../../../Images/Brands/Shipping/ll-logo.png';

function almaquietaReview() {
  return (
    <>
      <p style={{ fontStyle: 'oblique' }}>
        &quot;Nos encantó la facilidad para ligar nuestra tienda en Shopify con su servicio.
        <br />
        <br />
        El tiempo de entrega es muy bueno y los mensajeros son muy amables.
      </p>
      <p style={{ fontStyle: 'oblique', color: colors.eiyaPurple, marginTop: '2rem' }}>
        ¡Nos encanta que para nuestras entregas locales todo se haga en bicicleta!&quot;
      </p>
    </>
  );
}

function lushReview() {
  return (
    <>
      <p style={{ fontStyle: 'oblique' }}>&quot;Hacen todo la parte operativa muy sencilla,</p>
      <p style={{ fontStyle: 'oblique', color: colors.eiyaPurple, marginTop: '2rem' }}>
        Y los pedidos se entregan en tiempo record y en buenas condiciones.&quot;
      </p>
    </>
  );
}

function farmapielReview() {
  return (
    <>
      <p style={{ fontStyle: 'oblique', color: colors.eiyaPurple, marginBottom: '2rem' }}>&quot;¡Todo es increíble!</p>
      <p style={{ fontStyle: 'oblique' }}>
        El seguimiento, atención, amabilidad y sobre todo la formalidad en las entregas, nuestros
        clientes están muy contentos y por ende nosotros también.&quot;
      </p>
    </>
  );
}

function llReview() {
  return (
    <>
      <p style={{ fontStyle: 'oblique', color: colors.eiyaPurple, marginBottom: '2rem' }}>
      &quot;Nos gustan mucho los tiempos de entrega y el buen servicio que nos dan.
      </p>
      <p style={{ fontStyle: 'oblique' }}>Los pocos problemas que hemos tenido se han resuelto de una manera rápida y adecuada.&quot;</p>
    </>
  );
}

const ReviewData = [
  {
    review: almaquietaReview(),
    logo: { img: almaquietaLogo, size: '60%' },
    image: almaquieta,
  },
  {
    review: lushReview(),
    logo: { img: lushLogo, size: '60%' },
    image: lush,
  },
  {
    review: farmapielReview(),
    logo: { img: farmapielLogo, size: '60%' },
    image: farmapiel,
  },
  {
    review: llReview(),
    logo: { img: llLogo, size: '40%' },
    image: ll,
  },
];
export default ReviewData;
