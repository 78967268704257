/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { eiyaColor as colors } from '../../../utils/colors';
import map from '../../../Images/Shipping/envios-mapa.png';
import mapM from '../../../Images/map-mobile.png';

const { innerHeight } = window;
const realHeight = `${innerHeight}px`;

var isSafari = /constructor/i.test(window.HTMLElement);

const SectionWraper = styled.div`
  background: ${colors.eiyaPurple};
  width: 100%;
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 9rem;

  @media screen and (max-width: 1430px) {
    padding: 4rem 6rem;
  }

  @media screen and (max-width: 1330px) {
    padding: 3rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 3rem;
  }

  @media screen and (max-width: 1170px) {
    padding: 3rem 1rem;
  }
  
  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    margin-top: 0;
    height: 100%;
    width: 100%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

const SectionTitle = styled.p`
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: ${colors.white};
  margin: 0 2rem;

  @media screen and (max-width: 900px) {
    margin: 0;
    margin-bottom: 2rem;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.20000000298023224px;
  color: ${colors.white};
  margin-top: 4rem;
  width: 70%;

  @media screen and (max-width: 1330px) {
    margin-top: 3rem;
  }

  @media screen and (max-width: 900px) {
    margin-top: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.20000000298023224px;
  }
`;

const MapContainer = styled.img`
  height: 70%;
  width: auto;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
    height: 50%;
  }

  @media screen and (max-width: 1060px) {
    display: none;
    height: auto;
    padding: 0 2rem;
    width: unset;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
  
`;

const MapContainerM = styled.img`
  display: none;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1060px) {
    display: block;
    height: auto;
    padding: 0 2rem;
    width: unset;
  }

  @media screen and (max-width: 900px) {
    display: block;
    height: auto;
    padding: 0 2rem;
    width: unset;
  }
  

  @media screen and (max-width: 600px) {
    height: auto;
    padding: 0 2rem;
    width: 100%;
  }
`;

const Reference = styled.div`
  position: relative;
  bottom: 20%;

  @media screen and (max-width: 900px) {
    bottom: 10%;
  }
`;

function SecondSection() {
  return (
    <SectionWraper>
      <SectionContainer>
        <SectionTitle>Lleva tus productos a todo México</SectionTitle>
        <SectionDescription>
          Entregamos en{' '}
          <span style={{ color: colors.accentYellow, fontWeight: '700' }}>
            CDMX/ZM y Guadalajara
          </span>
          , en el resto del país, con paqueterías aliadas y tarifas preferenciales.
        </SectionDescription>
      </SectionContainer>
      <MapContainer src={map} alt="mapa" />
      <MapContainerM src={mapM} alt="mapa" />
      <Reference id="trackinglink" />
    </SectionWraper>
  );
}

export default SecondSection;
